import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Documentation, Table, Card } from 'cargo'
import { getWebhooks, createWebhooks } from 'src/actions/WebhooksActions'

import './Webhooks.scss'

const convertToHumanReadableName = ({ eventType }) => eventType
  .split(/[\s_]/)
  .map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
  .join(' ')

class Webhooks extends Component {
  componentDidMount() {
    this.props.getWebhooks()
  }

  createWebhook = (webhookType, webhookSubType) => () => this.props.createWebhooks([{ webhookType, webhookSubType }])

  createAllWebhooks = () => this.props.createWebhooks(
    this.props.webhooks.webhooks
      .filter(w => !w.id)
      .map(w => ({ webhookType: w.webhookType, webhookSubType: w.eventType }))
  )

  render() {
    const { webhooks, apiFailure, loading } = this.props.webhooks

    const tableText = loading ? 'Loading...' : (apiFailure ? 'Error fetching webhooks data. Refresh the page or try again later.' : null)

    return (
      <div style={{ marginTop: '20px' }}>
        <Card>
          <div>
            <h3>Webhooks</h3>
            <Documentation entryId='7AWVRlgZrW0oa1wxpVYRcs' />
          </div>
          <button
            style={{
              background: '#7B7AED',
              color: '#fff',
              borderRadius: 4,
              fontWeight: 'bold',
              padding: '8px 12px',
              fontSize: 10,
              marginBottom: 10
            }}
            onClick={ this.createAllWebhooks }
          >
            Create All Webhooks
          </button>
          <Table
            data={ webhooks }
            mapping={[
              { column: 'Webhook', content: convertToHumanReadableName },
              { column: 'Created', content: ({ created }) => created ? moment(created).format('MM-DD-YYYY hh:mm A') : 'Not Yet Created' },
              {
                column: 'Actions',
                content: ({ id, webhookType, eventType }) => (!id
                    ? <button onClick={ this.createWebhook(webhookType, eventType) }>Create Webhook</button>
                    : 'N/A'
                )
              }
            ]}
            emptyText={ tableText }
            style={{ width: 800 }}
          />
        </Card>
      </div>
    )
  }
}

export default connect(
  ({ webhooks }) => ({ webhooks }),
  { getWebhooks, createWebhooks }
)(Webhooks)