import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { CollapsibleCard, Documentation, toggleModal } from 'cargo'
import { getEventOverview, retryAllEvents, resetEventLogState } from 'src/actions/EventsActions'
import EventLog from 'src/components/EventLog'
import './EventDashboard.scss'

const getSubTypes = type => {
  switch(type) {
    case 'PENDING':
    case 'IGNORED_BY_CUSTOMER':
    case 'MISSING_MAPPING':
      return [
        { subType: 'ORDER', name: 'Order' },
        { subType: 'RETURN', name: 'Return' },
        { subType: 'INVENTORY_VALUE_CHANGE', name: 'Inventory Value Change' },
        { subType: 'SHIPMENT', name: 'Shipment' },
        { subType: 'CHANNEL_FEE', name: 'Channel Fee' }
      ]
    default: // PROCESSED and FAILED
      return [
        { subType: 'ORDER_SALES_RECEIPT', name: 'Order Sales Receipt' },
        { subType: 'ORDER_INVOICE', name: 'Order Invoice' },
        { subType: 'ORDER_JOURNAL_ENTRY', name: 'Order Journal Entry' },
        { subType: 'RETURN_REFUND_RECEIPT', name: 'Return Refund Receipt' },
        { subType: 'RETURN_JOURNAL_ENTRY', name: 'Return Journal Entry' },
        { subType: 'INVENTORY_VALUE_CHANGE', name: 'Inventory Value Change' },
        { subType: 'SHIPMENTS', name: 'Shipments' },
        { subType: 'SALES_CHANNEL_FEES', name: 'Sales Channel Fees' }
      ]
  }
}

const types = {
  FAILED: 'Failed Events',
  MISSING_MAPPING: 'Missing Mapping Events',
  PROCESSED: 'Processed Events',
  PENDING: 'Pending Events',
  IGNORED_BY_CUSTOMER: 'Ignored Events'
}

class EventDashboard extends PureComponent {
  componentDidMount() {
    this.props.getEventOverview()
  }

  toggleEventModal = (type, subType, title) => () => this.props.toggleModal({
    title,
    body: <EventLog type={ type } subTypes={ getSubTypes(type) } defaultSubType={ subType }/>,
    cancelText: 'Close',
    onCancel: () => {
      this.props.resetEventLogState()
      this.props.toggleModal()
    },
    beforeClose: this.props.resetEventLogState,
    confirmText: 'Retry All Events On This Page',
    confirmButtonDisabled: type === 'PROCESSED',
    onConfirm: this.props.retryAllEvents,
    modalWidth: 1200
  })

  render() {
    const { summary } = this.props.events

    const documentationEntries = {
      PENDING: '1djuaQ4PHz8kV1nk6I5V1p',
      IGNORED_BY_CUSTOMER: '6UgvCOOQQdnlq6p9uHlNJl',
      MISSING_MAPPING: '2rNAuyHW2yJXDiH1TtMZtj',
      PROCESSED: '3u6VwLkcon1ZBLcVg6Ly5Y',
      FAILED: '1M8LdNRxU5JcUcGHHpIvsS'
    }

    return (
      <div className='EventDashboard'>
        {Object.entries(types).map(([type, modalTitle]) => {
          const summaryType = ['PENDING', 'IGNORED_BY_CUSTOMER', 'MISSING_MAPPING'].includes(type) ? 'receivedEvents' : 'syncedEvents'

          return (
            <CollapsibleCard
              title={ modalTitle }
              key={ type }
              style={{ marginTop: 20 }}
              collapsed
              collapsedContent={
                <div>
                  { summary.loading
                    ? <div className='LoadingStatistics'>Loading...</div>
                    : <div className='EventTypesSummary' style={{ marginTop: 10 }}>
                        {getSubTypes(type).map(({ subType, name }) => {
                          const count = (summary[summaryType][subType][type] || {})[0] || 0
                          return (
                            <div className='CollapsedViewEventType' key={ type + subType + 'allTime' }>
                              <p>{ name }</p>
                              <p
                                className='EventCount'
                                style={{ color: count > 0 ? '#717CF4' : '#d0d0d0' }}
                              >
                                { count }
                              </p>
                            </div>
                          )
                        })}
                      </div>
                  }
                </div>
              }
            >
              <div style={{ marginTop: 10, fontSize: 12 }}>
                <Documentation entryId={ documentationEntries[type] }/>
              </div>
              <div className='EventTypesSummary'>
                {getSubTypes(type).map(({ subType, name }) => (
                  <div className='EventType' onClick={ this.toggleEventModal(type, subType, modalTitle) } key={ type + subType }>
                    <p>{ name }</p>
                    {summary.loading
                      ? <div className='LoadingStatistics'>Loading...</div>
                      : (
                        <div className='Statistics'>
                          {[['All Time', 0], ['1 day', 1], ['3 day', 3], ['7 day', 7], ['30 day', 30]].map(([ timeframe, idx ]) => {
                            const count = (summary[summaryType][subType][type] || {})[idx] || 0
                            return (
                              <div className='Statistic' key={ type + subType + idx }>
                                <p className='TimeFrame'>{ timeframe }</p>
                                <p className='EventCount' style={{ color: count > 0 ? '#717CF4' : '#d0d0d0' }}>{ count }</p>
                              </div>
                            )
                          })}
                        </div>
                      )
                    }
                  </div>
                ))}
                {(new Array(5 - (getSubTypes(type).length % 5)).fill(undefined)).map(
                  (_, i) => <div key={ i } className='EventType Hidden'/>
                )}
              </div>
            </CollapsibleCard>
          )
        })}
      </div>
    )
  }
}

export default connect(
  ({ events }) => ({ events }),
  { getEventOverview, retryAllEvents, resetEventLogState, toggleModal }
)(EventDashboard)