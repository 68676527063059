import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Button, pushModalLayer, popModalLayer } from 'cargo'
import moment from 'moment'
import JSONPretty from 'react-json-pretty'
import { getEventLog, retryFailedEvent, setSelectedSubType, setSelectedType } from 'src/actions/EventsActions'
import Utils from 'src/utils/Utils'

const jsonPrettyTheme = {
  main: 'line-height: 1.3; color: #BCC2CD; background: transparent;',
  error: 'line-height: 1.3; color: #f00; background: transparent;',
  key: 'color: #9580FF; font-weight: bold;',
  string: 'color: #BCC2CD;',
  value: 'color: #51FA7A;',
  boolean: 'color: #FF78C6;',
}

class EventLog extends Component {
  state = { currentPageSize: 50, search: '' }

  componentDidMount() {
    const {
      events: { selectedType, selectedSubType },
      type,
      defaultSubType,
      getEventLog,
      setSelectedType,
      setSelectedSubType
    } = this.props

    if (type !== selectedType && !selectedSubType) {
      getEventLog(type, defaultSubType, 0, this.state.currentPageSize, this.state.search)
      setSelectedType(type)
      setSelectedSubType(defaultSubType)
    }
  }

  retryEvent = (type, receivedEventId ) => this.props.retryFailedEvent(
    type,
    this.props.events.selectedSubType,
    receivedEventId
  )

  changeSubtype = subType => {
    this.setSearch('')
    this.props.setSelectedSubType(subType)
    this.props.getEventLog(this.props.type, subType, 0, this.state.currentPageSize, '')
    this.forceTablePage(1)
  }

  setSearch = search => this.setState({ search })

  searchEvents = () => {
    const { type, events: { selectedSubType }, getEventLog } = this.props
    const { currentPageSize, search } = this.state

    getEventLog(type, selectedSubType, 0, currentPageSize, search)
    this.forceTablePage(1)
  }

  openJsonContent = async (content, quickbooksId, subType) => {
    let slug;

    switch (subType) {
      case 'ORDER_SALES_RECEIPT':
        slug = 'salesreceipt'
        break
      case 'ORDER_INVOICE':
        slug = 'invoice'
        break
      case 'RETURN_REFUND_RECEIPT':
        slug = 'refundreceipt'
        break
      default:
        slug = 'journalentry'
    }

    const qboEventJsonResponse = await Utils.makeApiCall(`/quickbooks/accounting/${slug}/${quickbooksId}`)

    let qboEventJson
    if (qboEventJsonResponse.status === 200) {
      qboEventJson = await qboEventJsonResponse.json()
    } else {
      qboEventJson = 'Error loading QBO Event'
    }
    
    this.props.pushModalLayer({
      title: '',
      body: (
        <div style={{ width: '100%' }}>
          <div style={{ display: 'inline-block', width: '49%', float: 'left' }}>
            <h3>Event JSON</h3>
            <JSONPretty theme={ jsonPrettyTheme } data={ content } />
          </div>
          <div style={{ display: 'inline-block', width: '49%', float: 'left', paddingLeft: 10, borderLeft: '1px solid #d0d0d0' }}>
            <h3>QBO JSON</h3>
            <JSONPretty theme={ jsonPrettyTheme } data={ qboEventJson } />
          </div>
        </div>
      ),
      cancelText: 'Back',
      onCancel: this.props.popModalLayer,
      modalWidth: 1200
    })
  }

  forceTablePage = () => {}

  render() {
    const {
      events: {
        loading,
        totalLogSize,
        selectedSubType,
        selectedType
      },
      subTypes
    } = this.props

    return (
      <div>
        <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
          {subTypes.map(({ name, subType }) => (
            <span
              style={{
                cursor: 'pointer',
                color: subType === selectedSubType ? '#060707' : 'grey',
                display: 'inline-flex'
              }}
              onClick={ () => this.changeSubtype(subType) }
            >
              { name }
            </span>
          ))}
        </div>
        <div style={{ marginBottom: 10, marginTop: 10, position: 'relative', overflow: 'auto' }}>
          <Button
            primary
            onClick={ this.searchEvents }
            text='Search Events'
            color='#fff'
            style={{ fontSize: 12, padding: '5px 10px', float: 'right' }}
          />
          <input
            type='text'
            onChange={ ({ target: { value }}) => this.setSearch(value) }
            value={ this.state.search }
            style={{ border: '1px solid #d0d0d0', borderRadius: 0, marginRight: 5, width: 200, float: 'right', padding: 5 }}
          />
        </div>
        <div style={{ height: 500 }}>
          <Table
            data={ loading ? [] : this.props.events.log }
            mapping={ !['MISSING_MAPPING', 'IGNORED_BY_CUSTOMER', 'PENDING'].includes(selectedType)
              ? [
                  { column: 'Event ID', content: ({ id, quickbooksId, content }) => (
                    <p
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      title='View Event Content'
                      onClick={() => this.openJsonContent(content, quickbooksId, selectedSubType)}
                    >
                      { id }
                    </p>
                  )},
                  { column: 'External ID', content: ({ externalId }) => externalId },
                  { column: 'Error Message', content: ({ errorMessage }) => errorMessage },
                  { column: 'Retries', content: ({ retryCount }) => retryCount },
                  { column: 'Status', content: ({ syncStatus }) => syncStatus },
                  { column: 'Preview', content: ({ contentPreview }) => contentPreview },
                  { column: 'Created', content: ({ created }) => moment(created).format('MM-DD-YYYY h:mm:ss a') },
                  { column: 'Last Modified', content: ({ lastModified }) => lastModified ? moment(lastModified).format('MM-DD-YYYY h:mm:ss a') : '' },
                  { column: 'Actions', content: ({ receivedEventId, retryDisabled }) => (
                    <Button
                      primary
                      text='Retry Event'
                      onClick={(e) => {
                        e.stopPropagation()
                        this.retryEvent(selectedType, receivedEventId)
                      }}
                      disabled={ retryDisabled }
                      color='#fff'
                    />
                  )}
                ]
              : [
                  { column: 'Event ID', content: ({ id, quickbooksId, content }) => (
                    <p
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      title='View Event Content'
                      onClick={() => this.openJsonContent(content, quickbooksId, selectedSubType)}
                    >
                      { id }
                    </p>
                  )},
                  { column: 'Transaction ID', content: ({ externalTransactionId }) => (
                    <>
                      {externalTransactionId.split('_').map((x, i) => (<>{x}{i === externalTransactionId.split('_').length - 1 ? '' : '_'}<wbr/></>))}
                    </>
                  )},
                  { column: 'Mappings', content: ({
                      externalSalesChannelName,
                      externalWarehouseName,
                      externalShippingProviderName,
                      externalShippingCarrierName
                    }) => (
                      <>
                        {[
                          ['Sales Channel', externalSalesChannelName],
                          ['Warehouse', externalWarehouseName],
                          ['Shipping Provider', externalShippingProviderName],
                          ['Shipping Carrier', externalShippingCarrierName]
                        ].filter(([, n]) => n).map(([externalType, name]) => (
                            <p>{ externalType }: { name }</p>
                        ))}
                      </>
                    )},
                  { column: 'Preview', content: ({ contentPreview }) => contentPreview },
                  { column: 'Created', content: ({ created }) => moment(created).format('MM-DD-YYYY h:mm:ss a') },
                  { column: 'Last Modified', content: ({ lastModified }) => lastModified ? moment(lastModified).format('MM-DD-YYYY h:mm:ss a') : '' },
                  { column: 'Actions', content: event => (
                    <Button
                      primary
                      text='Retry Event'
                      onClick={ (e) => {
                        e.stopPropagation()
                        this.retryEvent(selectedType, event.id)
                      } }
                      disabled={ event.retryDisabled }
                      color='#fff'
                    />
                  )}
                ]
            }
            pagination={{
              totalRecords: totalLogSize,
              defaultPageSize: this.state.currentPageSize,
              reloadData: (pageNum, pageSize) => {
                this.setState({ currentPageSize: pageSize })
                this.props.getEventLog(selectedType, selectedSubType, pageNum - 1, pageSize, this.state.search !== '' ? this.state.search : null)
              }
            }}
            bindForcePageChange={ fn => this.forceTablePage = fn }
            emptyText={ loading ? 'Loading...' : "There aren't any events yet" }
            style={{ width: 1150 }}
            growWithContainer={ true }
          />
        </div>
      </div>
    )
  }
}

export default connect(
  ({ events }) => ({ events }),
  { getEventLog, retryFailedEvent, setSelectedSubType, setSelectedType, pushModalLayer, popModalLayer }
)(EventLog)