import {
  QUICKBOOKS_FETCH_OAUTH_URL,
  USER_SETTINGS_DATA,
  USER_SETTINGS_ERROR,
  USER_SETTINGS_FETCH_DATA,
  USER_SETTINGS_UPDATE_QBO_AUTH,
  USER_SETTINGS_UPDATE_SKUBANA_AUTH,
} from 'src/actions/QuickBooksActions'

const initialState = {
  loading: true,
  error: false,
  skubanaAuthorized: false,
  quickbooksAuthorized: false,
  quickbooksOauthUrl: undefined,
  customerSettings: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_SETTINGS_FETCH_DATA:
      return { ...state, ...payload, loading: true, error: false }
    case USER_SETTINGS_DATA:
      return { ...state, ...payload, loading: false, error: false }
    case USER_SETTINGS_ERROR:
      return { ...state, ...payload, loading: false }
    case QUICKBOOKS_FETCH_OAUTH_URL:
      return { ...state, quickbooksOauthUrl: payload }
    case USER_SETTINGS_UPDATE_SKUBANA_AUTH:
      return { ...state, skubanaAuthorized: payload }
    case USER_SETTINGS_UPDATE_QBO_AUTH:
      return { ...state, quickbooksAuthorized: payload }
    default:
      return state
  }
}
