import React from 'react'
import Utils from 'src/utils/Utils'
import logo from 'src/images/gotham_works_logo.svg'

export default () => (
  <div className='account-pages mt-5 mb-5'>
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-lg-5'>
          <div className='card'>
            <div className='card-header pt-3 pb-3 text-center bg-skubana'>
              <a href='/' className='logo logo-lg text-center' dangerouslySetInnerHTML={{ __html: logo }} />
            </div>
            <div className='card-body p-4'>
              <div className='text-center m-auto'>
                <h4 className='text-dark-50 text-center mt-4 font-weight-bold'>
                  Login Required
                </h4>
                <p className='text-muted mb-4'>
                  In order to continue, you must log into Skubana.
                </p>
              </div>
              <div className='form-group mb-0 text-center'>
                <button
                  onClick={ Utils.redirectToSkubana }
                  className='btn btn-primary'
                  type='submit'
                >
                  <i className='mdi mdi-home mr-1' />
                  Login with Skubana
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
