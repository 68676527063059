import Utils from 'src/utils/Utils'

export const DEPENDENCIES_HEALTH_CHECK_REQUEST = 'DEPENDENCIES_HEALTH_CHECK_REQUEST'
export const DEPENDENCIES_HEALTH_CHECK_SUCCESS = 'DEPENDENCIES_HEALTH_CHECK_SUCCESS'
export const DEPENDENCIES_HEALTH_CHECK_FAILURE = 'DEPENDENCIES_HEALTH_CHECK_FAILURE'
export const MAPPING_HEALTH_CHECK_REQUEST = 'MAPPING_HEALTH_CHECK_REQUEST'
export const MAPPING_HEALTH_CHECK_SUCCESS = 'MAPPING_HEALTH_CHECK_SUCCESS'
export const MAPPING_HEALTH_CHECK_FAILURE = 'MAPPING_HEALTH_CHECK_FAILURE'
export const EVENT_SUMMARY_REQUEST = 'EVENT_SUMMARY_REQUEST'
export const EVENT_SUMMARY_SUCCESS = 'EVENT_SUMMARY_SUCCESS'
export const EVENT_SUMMARY_FAILURE = 'EVENT_SUMMARY_FAILURE'

export const getEventSummary = () => async dispatch => {
  dispatch({ type: EVENT_SUMMARY_REQUEST })

  const eventSummaryResponse = await Utils.makeApiCall(
    '/events/summary',
    'GET',
    undefined,
    'daysBack=1,3,7'
  )

  if (eventSummaryResponse.status !== 200) dispatch({ type: EVENT_SUMMARY_FAILURE })

  const responseJson = await eventSummaryResponse.json()

  dispatch({ type: EVENT_SUMMARY_SUCCESS, payload: responseJson })
}

export const getDependencyHealthStatuses = () => async dispatch => {
  ['products', 'taxitems', 'shippingserviceitems', 'vendors', 'customers'].map(async endpoint => {
    dispatch({ type: DEPENDENCIES_HEALTH_CHECK_REQUEST, payload: endpoint })

    const response = await Utils.makeApiCall(`/accounthealth/${endpoint}`, 'GET')
    const responseJson = await (response.status === 200 ? response.json() : [])

    dispatch({
      type: DEPENDENCIES_HEALTH_CHECK_SUCCESS,
      payload: {
        type: endpoint,
        value: responseJson
      }
    })
  })
}

export const getMappingHealthStatuses = () => async dispatch => {
  dispatch({ type: MAPPING_HEALTH_CHECK_REQUEST })
  const mappingStatusHealthResponse = await Utils.makeApiCall('/accounthealth/mappingstatus', 'GET')

  if (mappingStatusHealthResponse.status !== 200) dispatch({ type: MAPPING_HEALTH_CHECK_FAILURE })

  const responseJson = await mappingStatusHealthResponse.json()

  dispatch({
    type: MAPPING_HEALTH_CHECK_SUCCESS,
    payload: responseJson.map(r => ({ id: r.externalId, ...r }))
  })
}