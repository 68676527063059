import {
  QUICKBOOKS_ACCOUNT_DATA,
  QUICKBOOKS_ACCOUNT_ERROR,
  QUICKBOOKS_FETCH_DATA,
} from 'src/actions/QuickBooksActions'

const initialState = {
  loading: true,
  quickBooksVendors: [],
  shippingProviderError: false,
  quickbooksAccounts: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {

    case QUICKBOOKS_FETCH_DATA:
      return { ...state, ...payload, loading: true }
    case QUICKBOOKS_ACCOUNT_DATA:
      return { ...state, ...payload, loading: false }
    case QUICKBOOKS_ACCOUNT_ERROR:
      return { ...state, ...payload, loading: false }

    default:
      return state
  }
}

