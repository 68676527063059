import React, { useState } from 'react'
import { connect } from 'react-redux'
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/fontawesome-free-solid'
import Utils from 'src/utils/Utils'

import './MappingStatus.scss'

fontawesome.library.add(faMinusCircle)

const extraDefaultAccounts = {
  'Inventory Asset (In Transit)': {
    accountTypes: ['OTHER_CURRENT_ASSET'],
    accountSubTypes: ['Inventory'],
    idField: 'inTransitInventoryAssetAccountId'
  },
  'Income—Sales of Products': {
    accountTypes: ['INCOME'],
    accountSubTypes: ['SalesOfProductIncome'],
    idField: 'incomeSalesOfProductIncomeAccountId'
  },
  'Accounts Payable': {
    accountTypes: ['ACCOUNTS_PAYABLE', 'OTHER_CURRENT_ASSET'],
    accountSubTypes: ['AccountsPayable', 'Inventory'],
    idField: 'accountsPayableAccountId'
  }
}

const buildFieldsList = (fields, quickbooksAccounts) => {
  const extraFields = Object.entries(extraDefaultAccounts).map(([title, { accountTypes, accountSubTypes, idField }]) => {
    const filteredAccounts = Utils.filterAccountsByType(quickbooksAccounts, accountTypes, accountSubTypes)

    return { title, options: filteredAccounts, key: idField }
  })

  return [...fields, ...extraFields]
}

const MappingStatus = ({ status = {}, fields, isDefaultMapping, quickbooks: { quickbooksAccounts } }) => {
  const [isHovered, toggleHoverState] = useState(false)
  const { errorMessages = [], warningMessages = [] } = status
  const hasStatus = Object.entries(status).length > 0
  const [icon, color] = (hasStatus && !isDefaultMapping)
    ? (errorMessages.length
      ? ['times-circle', 'red']
      : (warningMessages.length
        ? ['exclamation-circle', 'orange']
        : ['check-circle', 'green']))
    : ['minus-circle', 'grey']

  const allFields = buildFieldsList(fields, quickbooksAccounts)

  return (
    <div className='MappingStatus'>
      <div
        onMouseOver={ () => toggleHoverState(true) }
        onMouseLeave={ () => toggleHoverState(false) }
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        <FontAwesomeIcon
          icon={ icon }
          color={ color }
          size='2x'
        />
      </div>
      {isHovered && (
        <div className='MappingStatusHoverText'>
          {isDefaultMapping
            ? <p>Default mappings have no mapping status.</p>
            : (
              <>
                <p>
                  { !hasStatus ? 'This mapping is not configured. Save the mapping in order to get a mapping status' :
                    !errorMessages.length && !warningMessages.length
                      ?  'This mapping is correctly set up'
                      :  <span>There are some issues with this mapping:<br/></span>
                  }
                </p>
                {errorMessages.length > 0 && (
                  <div className='Messages'>
                  <p><strong>Errors</strong></p>
                  <ul>
                  { errorMessages.map(m => <li>{ m }</li>) }
                  </ul>
                  </div>
                )}
                {warningMessages.length > 0 && (
                  <div className='Messages'>
                  <p><strong>Warnings</strong></p>
                  <ul>
                  {warningMessages.map(m => <li>{ m }</li>)}
                  </ul>
                  </div>
                )}
                {hasStatus && (
                  <div className='Messages'>
                  <p><strong>Current Mappings</strong></p>
                  {Object.entries(status).filter(([k, v]) => !['inventoryValueChangeActionType', 'warningMessages', 'errorMessages', 'type'].includes(k) && v !== null).map(([k, v]) => {
                    const field = allFields.find(({ key }) => key === k)
                    const fieldValue = field.options.find(({ id }) => id === v)
                    const fieldValueName = fieldValue ? fieldValue.name : ''

                    return <p>{ field.title } → { fieldValueName }</p>
                  })}
                  </div>
                )}
              </>
            )
          }
        </div>
      )}
    </div>
  )
}

export default connect(({ quickbooks }) => ({ quickbooks }))(MappingStatus)