import { combineCargoReducers } from 'cargo'
import QuickBooksReducer from './QuickBooksReducer'
import UserReducer from './UserReducer'
import UserSettingsReducer from './UserSettingsReducer'
import ShippingReducer from './ShippingReducer'
import ToastReducer from './ToastReducer'
import WebhooksReducer from './WebhooksReducer'
import EventsReducer from './EventsReducer'
import HealthDashboardReducer from './HealthDashboardReducer'

export default combineCargoReducers({
  quickbooks: QuickBooksReducer,
  shipping: ShippingReducer,
  user: UserReducer,
  userSettings: UserSettingsReducer,
  messages: ToastReducer,
  webhooks: WebhooksReducer,
  events: EventsReducer,
  health: HealthDashboardReducer
})
