import React from 'react'
import { Route, useRouteMatch } from "react-router-dom"
import CustomerAccount from "src/pages/Account/CustomerAccount"
import AdminAccount from "src/pages/Account/AdminAccount"
import Warehouses from "src/pages/Warehouses/Warehouses"
import SalesChannels from "src/pages/SalesChannels/SalesChannels"
import ShippingProviders from "src/pages/ShippingProviders/ShippingProviders"
import ShippingCarriers from "src/pages/ShippingCarriers/ShippingCarriers"
import GlobalMappings from "src/pages/GlobalMappings/GlobalMappings"
import Error from 'src/components/Error'
import EULAPage from 'src/pages/EULA/EULA'
import PrivacyPolicyPage from 'src/pages/PrivacyPolicy/PrivacyPolicy'
import Webhooks from 'src/pages/Webhooks/Webhooks'
import EventDashboard from 'src/pages/EventDashboard/EventDashboard'
import HealthDashboard from 'src/pages/HealthDashboard/HealthDashboard'

export default ({ admin, authenticated, quickbooksAuthorized }) => {
  const match = useRouteMatch()

  if (authenticated && admin && quickbooksAuthorized) {
    return (
      <div className='container-fluid'>
        <Route path={ match.path } exact component={ AdminAccount } />
        <Route path={`${match.path}/mappings/warehouses`} component={ Warehouses } />
        <Route path={`${match.path}/mappings/saleschannels`} component= {SalesChannels } />
        <Route path={`${match.path}/mappings/shippingproviders`} component={ ShippingProviders } />
        <Route path={`${match.path}/mappings/shippingcarriers`} component={ ShippingCarriers } />
        <Route path={`${match.path}/mappings/defaults`} component={ GlobalMappings } />
        <Route path={`${match.path}/webhooks`} component={ Webhooks } />
        <Route path={`${match.path}/events`} component={ EventDashboard } />
        <Route path={`${match.path}/health`} component={ HealthDashboard } />
        <Route path='/eula' component={ EULAPage } />
        <Route path='/privacy' component={ PrivacyPolicyPage } />
      </div>
    )
  }

  return (
    <div className='container-fluid'>
      <Route path='/error' exact component={ Error } />
      <Route path='/eula' component={ EULAPage } />
      <Route path='/privacy' component={ PrivacyPolicyPage } />
      <Route component={ CustomerAccount } />
    </div>
  )
}
