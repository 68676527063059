import moment from 'moment'
import {
  EVENTS_OVERVIEW_REQUEST,
  EVENTS_OVERVIEW_SUCCESS,
  EVENTS_OVERVIEW_FAILURE,
  GET_EVENT_LOG_REQUEST,
  GET_EVENT_LOG_SUCCESS,
  GET_EVENT_LOG_FAILURE,
  RETRY_FAILED_EVENT_REQUEST,
  RETRY_FAILED_EVENT_SUCCESS,
  RETRY_FAILED_EVENT_FAILURE,
  SET_SELECTED_TYPE,
  SET_SELECTED_SUBTYPE,
  RESET_EVENT_LOG_STATE
} from 'src/actions/EventsActions'

const initialState = {
  log: [],
  totalLogSize: 0,
  loading: false,
  summary: {
    loading: true,
    syncedEvents: {},
    receivedEvents: {}
  },
  selectedType: '',
  selectedSubType: '',
  externalAccounts: []
}

export default (state = initialState, { payload, type }) => {
  switch(type) {
    case SET_SELECTED_TYPE:
      return { ...state, selectedType: payload }
    case SET_SELECTED_SUBTYPE:
      return { ...state, selectedSubType: payload }
    case RETRY_FAILED_EVENT_REQUEST: {
      const eventCategoryIdField = ['MISSING_MAPPING', 'IGNORED_BY_CUSTOMER', 'PENDING']
        .includes(state.selectedType) ? 'id' : 'receivedEventId'

      return { ...state, log: state.log.map(l => ({ ...l, retryDisabled: state.selectedType === 'PROCESSED' || payload.includes(l[eventCategoryIdField]) })) }
    }
    case GET_EVENT_LOG_REQUEST:
      return { ...state, loading: true }
    case EVENTS_OVERVIEW_REQUEST:
      return { ...state, summary: { loading: true } }
    case EVENTS_OVERVIEW_SUCCESS:
      const syncedEvents = payload.syncedEvents
        .reduce((acc, { daysBack, syncedEventType, syncedFailed, syncedProcessed }) => ({
          ...acc,
          [syncedEventType]: {
            PROCESSED: {
              ...(acc[syncedEventType] || { PROCESSED: {} }).PROCESSED,
              [daysBack]: ((acc[syncedEventType] || { PROCESSED: {} })[daysBack] || 0) + syncedProcessed
            },
            FAILED: {
              ...(acc[syncedEventType] || { FAILED: {} }).FAILED,
              [daysBack]: ((acc[syncedEventType] || { FAILED: {} })[daysBack] || 0) + syncedFailed
            }
          }
        }), {})

      const receivedEvents = payload.receivedEvents
        .reduce((acc, { daysBack, receivedEventType, receivedIgnoredByCustomer, receivedPending, receivedMissingMapping, receivedProcessed }) => ({
          ...acc,
          [receivedEventType]: {
            PENDING: {
              ...(acc[receivedEventType] || { PENDING: {} }).PENDING,
              [daysBack]: ((acc[receivedEventType] || { PENDING: {} })[daysBack] || 0) + receivedPending
            },
            MISSING_MAPPING: {
              ...(acc[receivedEventType] || { MISSING_MAPPING: {} }).MISSING_MAPPING,
              [daysBack]: ((acc[receivedEventType] || { MISSING_MAPPING: {} })[daysBack] || 0) + receivedMissingMapping
            },
            IGNORED_BY_CUSTOMER: {
              ...(acc[receivedEventType] || { IGNORED_BY_CUSTOMER: {} }).IGNORED_BY_CUSTOMER,
              [daysBack]: ((acc[receivedEventType] || { IGNORED_BY_CUSTOMER: {} })[daysBack] || 0) + receivedIgnoredByCustomer
            }
          }
        }), {})

      return { ...state, summary: { syncedEvents, receivedEvents, loading: false } }
    case EVENTS_OVERVIEW_FAILURE:
      return state
    case RESET_EVENT_LOG_STATE:
      return { ...initialState, summary: { ...state.summary } }
    case GET_EVENT_LOG_SUCCESS: {
      const log = payload.content.map(e => ({
        ...e,
        retryDisabled: state.selectedType === 'PROCESSED' || (e.lastModified && moment(e.lastModified).isAfter(moment().subtract(10, 'minutes')))
      }))

      return { ...state, totalLogSize: payload.totalElements, log, loading: false }
    }
    case GET_EVENT_LOG_FAILURE:
      return state
    case RETRY_FAILED_EVENT_SUCCESS:
      return { ...state }
    case RETRY_FAILED_EVENT_FAILURE: {
      const eventCategoryIdField = ['MISSING_MAPPING', 'IGNORED_BY_CUSTOMER', 'PENDING']
        .includes(state.selectedType) ? 'id' : 'receivedEventId'

      return {
        ...state,
        log: state.log.map(l => ({ ...l, retryDisabled: payload.includes(l[eventCategoryIdField]) ? false : l.retryDisabled }))
      }
    }
    default:
      return state
  }
}