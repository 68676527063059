import React, { Component } from 'react'
import DocumentedLabel from './DocumentedLabel';
import './SearchableSelectWithLabel.scss'

export default class SearchableSelectWithLabel extends Component {
  state = {
    options: [{ id: null, name: this.props.defaultName }],
    dropdownShown: false,
    selected: this.props.selected
  }

  componentDidMount() {
    this.props.onSearch(
      '',
      options => this.setState({ options: [ ...this.state.options, ...options ]})
    )
  }

  onSearch = ({ target: { value } }) => this.props.onSearch(value, options => this.setState({ options }))

  toggleDropdown = shown => () => this.setState({ dropdownShown: shown })

  handleSelect = id => () => {
    this.props.onChange({ target: { value: id } })
    this.setState({ dropdownShown: false, selected: id })
  }

  handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.toggleDropdown(false)()
    }
  }

  render() {
    const { disabled, title, documentationId } = this.props
    const { options, dropdownShown, selected } = this.state
    let selectedOption = options.find(o => o.id === selected)

    if (selectedOption && !disabled) {
      selectedOption.selected = true
    } else {
      options[0].selected = true
      selectedOption = options[0]
    }

    return (
      <div className='SelectContainer SearchableDropdown'>
        <DocumentedLabel documentationId={ documentationId }>
          { title }
        </DocumentedLabel>
        <div
          className='SelectDropdown'
          onBlur={ this.handleBlur }
          style={{ zIndex: 100 }}
          tabIndex={ 1 }
        >
          <div className='SelectComponent' onClick={ this.toggleDropdown(!this.state.dropdownShown) }>
            { selectedOption.name }
          </div>
          {dropdownShown && (
            <div className='Dropdown'>
              <input type='text' placeholder='Search...' onChange={ this.onSearch } />
              <div className='Options'>
                {options.map(({ id, name }) => (
                  <div onClick={ this.handleSelect(id) }>
                    { name }
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}