import React from 'react'

import './ToggleSwitch.scss'

const ToggleSwitch = ({ enabled, onChange }) => (
  <div className={`SwitchContainer ${enabled ? 'enabled' : ''}`} onClick={ () => onChange({ target: { value: !enabled } }) }>
    <div className='ToggleSlider'>&nbsp;</div>
  </div>
)

export default ToggleSwitch