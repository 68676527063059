import React, { PureComponent } from 'react'

import './Settings.scss'
import DocumentedLabel from './DocumentedLabel'
import ToggleSwitch from './ToggleSwitch';

export default class CheckboxSettings extends PureComponent {
  render() {
    const { rows, handleSettingChange, sectionTitle, sectionKeyHeader, sectionOptionHeader } = this.props

    if (rows == null || (Array.isArray(rows) && rows.length < 1)) {
      return ''
    }

    return (
      <div className='Section'>
        <div className='SectionHeader'>
          <p className='SectionTitle'>{sectionTitle}</p>
        </div>
        <div className='SettingRowContainer'>
          <div className='SettingHeader'>
            <div><p>{sectionKeyHeader}</p></div>
            <div><p>{sectionOptionHeader}</p></div>
          </div>
          {rows.map(({ displayText, key, enabled, documentationId }) => {
            return (
              <div className='SettingRow' key={ key }>
                <div>
                  <DocumentedLabel documentationId={ documentationId }>
                    { displayText }
                  </DocumentedLabel>
                </div>
                <div>
                  <ToggleSwitch
                    enabled={ enabled}
                    onChange={ handleSettingChange(key, 'enabled') }
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
