import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {
    getWarehouseMappingStatuses,
    getWarehouseMappings,
    submitWarehouseMappings
} from "src/actions/QuickBooksActions"
import Utils from "src/utils/Utils"
import Loader from 'src/components/Loader'
import MappingSettings from 'src/components/MappingSettings'

class Warehouses extends PureComponent {
    orderShippedOptions = [
        {id: 'ORDER_SHIP_DATE', name: 'Order Ship Date'},
        {id: 'ORDER_DATE', name: 'Order Date'},
        {id: 'ORDER_PAYMENT_DATE', name: 'Order Payment Date'},
        {id: 'ORDER_CREATED_DATE', name: 'Order Created Date'},
        {id: 'ORDER_SHIPMENT_CREATED_DATE', name: 'Shipment Created Date'},
        {id: 'ORDER_SHIPMENT_LABEL_CREATED_DATE', name: 'Shipment Label Created Date'}
    ]

    poItemCustomizationOptions = [
        {id: 'PO_ITEM_RECEIVED_DATE', name: 'Received Date'},
        {id: 'PO_ITEM_BILLED_DATE', name: 'Billed Date'},
        {id: 'PO_ITEM_ESTIMATED_DELIVERY_DATE', name: 'Estimated Delivery Date'},
        {id: 'PO_ITEM_DELIVERY_DATE', name: 'Delivery Date'},
        {id: 'PO_ITEM_LANDED_COST_PAYMENT_DATE', name: 'Landed Cost Payment Date'}
    ]

    generateSections = () => {
        const filterAccounts = (types, subtypes) => Utils.filterAccountsByType(this.props.quickbooks.quickbooksAccounts, types, subtypes)

        return {
            accountMapping: [
                {
                    title: 'Inventory Asset',
                    key: 'inventoryAssetAccountId',
                    documentationId: '6qgjgxM3WZe0094KEbe51X',
                    options: filterAccounts(['OTHER_CURRENT_ASSET'], ['Inventory'])
                },
                {
                    title: 'Supplies & Materials - COGS',
                    key: 'cogsAccountId',
                    documentationId: '2OY7Qr8STmA0GnS1UdEXlR',
                    options: filterAccounts(['COST_OF_GOODS_SOLD'], ['SuppliesMaterialsCogs'])
                },
                {
                    title: 'Inventory Write-off Account',
                    key: 'writeOffAccountId',
                    documentationId: '6dmv9YfpjCvpwRHzNvaUhH',
                    options: filterAccounts(['COST_OF_GOODS_SOLD', 'EXPENSE'], ['SuppliesMaterialsCogs', 'SuppliesMaterials'])
                }
            ],
            tracking: [
                {
                    title: 'PO Item Received',
                    key: 'poItemReceivedTrackSetting',
                    documentationId: '6gDg5MZ4OeJr3hL3dqj6iI'
                },
                {
                    title: 'PO Item Modified',
                    key: 'poItemModifiedTrackSetting',
                    documentationId: '41gUm7ZvUBdKucPlZXx1eB'
                },
                {
                    title: 'PO Item Adjusted',
                    key: 'poItemAdjustTrackSetting',
                    documentationId: '6N55MOcg4L0l8MR5JcaH6q'
                },
                {
                    title: 'Order Shipped',
                    key: 'orderShippedTrackSetting',
                    documentationId: '7uijB7zIoe5JVlkoIYU76b'
                },
                {
                    title: 'Order Returned',
                    key: 'orderReturnedTrackSetting',
                    documentationId: '2rQX4ngY66YCznYO0zlIYW'
                },
                {
                    title: 'Shipment Voided',
                    key: 'shipmentVoidedTrackSetting',
                    documentationId: '7zOd3v0CUAbIAn2huwdvSI'
                },
                {
                    title: 'Marketplace Shipment Received',
                    key: 'marketplaceShipmentReceivedTrackSetting',
                    documentationId: 'ZOFX3k2tW6isSes7wYP2H'
                },
                {
                    title: 'Marketplace Shipment Shipped',
                    key: 'marketplaceShipmentShippedTrackSetting',
                    documentationId: '45ZviuRalmJixbUKhpn22y'
                },
                {
                    title: 'Marketplace Shipment Adjusted',
                    key: 'marketplaceShipmentAdjustTrackSetting',
                    documentationId: '5wQ8jKixmEWqHSQQmfXNti'
                },
                {
                    title: 'STO Item Received',
                    key: 'stoItemReceivedTrackSetting',
                    documentationId: '3kjd1yyem8pNH0cwbUMQQh'
                },
                {
                    title: 'STO Item Shipped',
                    key: 'stoItemShippedTrackSetting',
                    documentationId: '1ltITrNhvXN8cGtpnVzoyl'
                },
                {
                    title: 'STO Item Adjusted',
                    key: 'stoItemAdjustTrackSetting',
                    documentationId: '6pF0Ov4m8h8zNvtcwcrtUd'
                },
                {
                    title: 'Manually Added Inventory',
                    key: 'addInventoryTrackSetting',
                    documentationId: '5Cw6ONtzWqspAAcVgb71Mf'
                },
                {
                    title: 'Manually Removed Inventory',
                    key: 'removeInventoryTrackSetting',
                    documentationId: '6I2ywblo0gvsVPIUHPvYPx'
                }
            ],
            customization: [
                {
                    title: 'PO Item Received',
                    key: 'poItemReceivedCustomizationSettings',
                    customizationOptions: this.poItemCustomizationOptions
                },
                {
                    title: 'PO Item Modified',
                    key: 'poItemModifiedCustomizationSettings',
                    customizationOptions: this.poItemCustomizationOptions
                },
                {
                    title: 'PO Item Adjusted',
                    key: 'poItemAdjustCustomizationSettings',
                    customizationOptions: this.poItemCustomizationOptions
                },
                {
                    title: 'Order Shipped',
                    key: 'orderShippedCustomizationSettings',
                    customizationOptions: this.orderShippedOptions
                },
                {
                    title: 'Order Returned',
                    key: 'orderReturnedCustomizationSettings',
                    customizationOptions: [
                        {id: 'RMA_ISSUE_DATE', name: 'RMA Issue Date'}
                    ].concat(this.orderShippedOptions)
                },
                {
                    title: 'Shipment Voided',
                    key: 'shipmentVoidedCustomizationSettings',
                    customizationOptions: this.orderShippedOptions
                },
                {
                    title: 'Marketplace Shipment Received',
                    key: 'marketplaceShipmentReceivedCustomizationSettings',
                    customizationOptions: [{
                        id: 'SHIPMENT_RECEIVED_DATE',
                        name: 'Shipment Received Date'
                    }].concat(this.orderShippedOptions)
                },
                {
                    title: 'Marketplace Shipment Shipped',
                    key: 'marketplaceShipmentShippedCustomizationSettings',
                    customizationOptions: this.orderShippedOptions
                },
                {
                    title: 'Marketplace Shipment Adjusted',
                    key: 'marketplaceShipmentAdjustCustomizationSettings',
                    customizationOptions: this.poItemCustomizationOptions
                },
                {
                    title: 'STO Item Received',
                    key: 'stoItemReceivedCustomizationSettings',
                    customizationOptions: [{
                        id: 'SHIPMENT_RECEIVED_DATE',
                        name: 'Shipment Received Date'
                    }].concat(this.orderShippedOptions)
                },
                {
                    title: 'STO Item Shipped',
                    key: 'stoItemShippedCustomizationSettings',
                    customizationOptions: this.orderShippedOptions
                },
                {
                    title: 'STO Item Adjusted',
                    key: 'stoItemAdjustCustomizationSettings',
                    customizationOptions: this.poItemCustomizationOptions
                },
                {
                    title: 'Manually Added Inventory',
                    key: 'addInventoryCustomizationSettings',
                    customizationOptions: [{
                        id: 'ADJUSTMENT_DATE',
                        name: 'Adjustment Date'
                    }].concat(this.orderShippedOptions)
                },
                {
                    title: 'Manually Removed Inventory',
                    key: 'removeInventoryCustomizationSettings',
                    customizationOptions: [{
                        id: 'ADJUSTMENT_DATE',
                        name: 'Adjustment Date'
                    }].concat(this.orderShippedOptions)
                }
            ],
            templates: [
                {
                    title: 'PO Item Received',
                    key: 'poItemReceivedTemplateSettings',
                    idField: "poItemReceivedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Vendor Name', templateInputValue: '{{vendorName}}'},
                        {templateDisplayValue: 'Vendor Email', templateInputValue: '{{vendorEmail}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'},
                        {templateDisplayValue: 'Purchase Order Number', templateInputValue: '{{purchaseOrderNumber}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'PO Item Modified',
                    key: 'poItemModifiedTemplateSettings',
                    idField: "poItemModifiedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Vendor Name', templateInputValue: '{{vendorName}}'},
                        {templateDisplayValue: 'Vendor Email', templateInputValue: '{{vendorEmail}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'},
                        {templateDisplayValue: 'Purchase Order Number', templateInputValue: '{{purchaseOrderNumber}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'PO Item Adjusted',
                    key: 'poItemAdjustTemplateSettings',
                    idField: "poItemAdjustTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Vendor Name', templateInputValue: '{{vendorName}}'},
                        {templateDisplayValue: 'Vendor Email', templateInputValue: '{{vendorEmail}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'},
                        {templateDisplayValue: 'Purchase Order Number', templateInputValue: '{{purchaseOrderNumber}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Order Shipped',
                    key: 'shipmentCreatedTemplateSettings',
                    idField: "shipmentCreatedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Order Returned',
                    key: 'orderReturnedTemplateSettings',
                    idField: "orderReturnedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Shipment Voided',
                    key: 'shipmentVoidedTemplateSettings',
                    idField: "shipmentVoidedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Marketplace Shipment Received',
                    key: 'marketplaceShipmentReceivedTemplateSettings',
                    idField: "marketplaceShipmentReceivedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Marketplace Shipment Shipped',
                    key: 'marketplaceShipmentShippedTemplateSettings',
                    idField: "marketplaceShipmentShippedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Marketplace Shipment Adjusted',
                    key: 'marketplaceShipmentAdjustTemplateSettings',
                    idField: "marketplaceShipmentAdjustTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'STO Item Received',
                    key: 'stoItemReceivedTemplateSettings',
                    idField: "stoItemReceivedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'STO Item Shipped',
                    key: 'stoItemShippedTemplateSettings',
                    idField: "stoItemShippedTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'STO Item Adjusted',
                    key: 'stoItemAdjustTemplateSettings',
                    idField: "stoItemAdjustTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Manually Added Inventory',
                    key: 'addInventoryTemplateSettings',
                    idField: "addInventoryTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                },
                {
                    title: 'Manually Removed Inventory',
                    key: 'removeInventoryTemplateSettings',
                    idField: "removeInventoryTemplateSettings",
                    templatingValues: [
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}'},
                        {templateDisplayValue: 'Warehouse Name', templateInputValue: '{{warehouseName}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                }
            ]
        }
    }

    render() {
        const {quickbooks} = this.props
        const headerRow = [
            'Warehouse', 'Inventory Asset', 'Supplies & Materials - COGS', 'Inventory Write-off Account', 'Actions'
        ]

        const displayRows = [
            'inventoryAssetAccountId', 'cogsAccountId', 'writeOffAccountId'
        ]

        if (!quickbooks.quickbooksAccounts.length > 0) return <Loader/>

        return <MappingSettings
            title='Warehouses'
            documentationId='2HPdedKDDkKLq91SNn2aFj'
            getMappingStatuses={getWarehouseMappingStatuses}
            headerRow={headerRow}
            displayRows={displayRows}
            idField='warehouseId'
            getMappings={getWarehouseMappings}
            submitMapping={submitWarehouseMappings}
            quickbooks={quickbooks}
            generateSections={this.generateSections}
        />
    }
}

export default connect(({quickbooks}) => ({quickbooks}), null)(Warehouses)
