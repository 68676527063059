import Utils from 'src/utils/Utils'
import { addMessage } from 'src/actions/ToastActions'

export const EVENTS_OVERVIEW_REQUEST = 'EVENTS_OVERVIEW_REQUEST'
export const EVENTS_OVERVIEW_SUCCESS = 'EVENTS_OVERVIEW_SUCCESS'
export const EVENTS_OVERVIEW_FAILURE = 'EVENTS_OVERVIEW_FAILURE'
export const GET_EVENT_LOG_REQUEST = 'GET_EVENT_LOG_REQUEST'
export const GET_EVENT_LOG_SUCCESS = 'GET_EVENT_LOG_SUCCESS'
export const GET_EVENT_LOG_FAILURE = 'GET_EVENT_LOG_FAILURE'
export const RETRY_FAILED_EVENT_REQUEST = 'RETRY_FAILED_EVENT_REQUEST'
export const RETRY_FAILED_EVENT_SUCCESS = 'RETRY_FAILED_EVENT_SUCCESS'
export const RETRY_FAILED_EVENT_FAILURE = 'RETRY_FAILED_EVENT_FAILURE'
export const SET_SELECTED_TYPE = 'SET_SELECTED_TYPE'
export const SET_SELECTED_SUBTYPE = 'SET_SELECTED_SUBTYPE'
export const RESET_EVENT_LOG_STATE = 'RESET_EVENT_LOG_STATE'

export const setSelectedType = type => dispatch => dispatch({
  type: SET_SELECTED_TYPE,
  payload: type
})

export const setSelectedSubType = subType => dispatch => dispatch({
  type: SET_SELECTED_SUBTYPE,
  payload: subType
})

export const resetEventLogState = () => dispatch => dispatch({ type: RESET_EVENT_LOG_STATE })

export const getEventOverview = () => async dispatch => {
  dispatch({ type: EVENTS_OVERVIEW_REQUEST })

  const eventsReceivedResponse = await Utils.makeApiCall(
    '/events/received/summary',
    'GET',
    undefined,
    'daysBack=1,3,7,30'
  )
  const receivedEvents = await eventsReceivedResponse.json()

  const eventsSyncedResponse = await Utils.makeApiCall(
    '/events/synced/summary',
    'GET',
    undefined,
    'daysBack=1,3,7,30'
  )
  const syncedEvents = await eventsSyncedResponse.json()

  dispatch({ type: EVENTS_OVERVIEW_SUCCESS, payload: { syncedEvents, receivedEvents } })
}

export const getEventLog = (type, subtype, page, pageSize, search = '') => async dispatch => {
  dispatch({ type: GET_EVENT_LOG_REQUEST })

  let eventLogResponse

  if (['PENDING', 'IGNORED_BY_CUSTOMER', 'MISSING_MAPPING'].includes(type)) {
    eventLogResponse = await Utils.makeApiCall(
      '/events/received',
      'GET',
      undefined,
      `receivedEventType=${subtype}&pageNum=${page}&size=${pageSize}&mappingStatus=${type}&search=${search}`
    )
  } else {
    eventLogResponse = await Utils.makeApiCall(
      '/events/synced',
      'GET',
      undefined,
      `syncedEventType=${subtype}&pageNum=${page}&size=${pageSize}&syncStatus=${type}&search=${search}`
    )
  }

  const eventLogResponseJson = await eventLogResponse.json()

  dispatch({ type: GET_EVENT_LOG_SUCCESS, payload: eventLogResponseJson })
}

export const retryFailedEvent = (type, subType, eventId) => async dispatch => {
  dispatch({ type: RETRY_FAILED_EVENT_REQUEST, payload: [eventId] })

  const response = await retryEvents(type, subType, [eventId])

  if (response.status === 200) {
    addMessage({ type: 'Success', message: 'Event(s) retried successfully.' })(dispatch)
    dispatch({ type: RETRY_FAILED_EVENT_SUCCESS })
  } else {
    addMessage({ type: 'Failure', message: 'Something went wrong when retrying your event(s). Please try again.' })(dispatch)
    dispatch({ type: RETRY_FAILED_EVENT_FAILURE, payload: [eventId] })
  }
}

const retryEvents = async (type, subType, eventIds) => {
  let eventRetryResponse

  if (['PENDING', 'IGNORED_BY_CUSTOMER', 'MISSING_MAPPING'].includes(type)) {
    eventRetryResponse = await Utils.makeApiCall(
      '/events/received/retry',
      'POST',
      undefined,
      `receivedEventType=${subType}&receivedEventIds=${eventIds}`
    )
  } else {
    eventRetryResponse = await Utils.makeApiCall(
      '/events/synced/retry',
      'POST',
      undefined,
      `syncedEventType=${subType}&receivedEventIds=${eventIds}`
    )
  }

  return eventRetryResponse
}

export const retryAllEvents = () => async (dispatch, getState) => {
  const { events: { selectedType, selectedSubType } } = getState()

  const eventCategoryIdField = ['MISSING_MAPPING', 'IGNORED_BY_CUSTOMER', 'PENDING']
    .includes(getState().events.selectedType) ? 'id' : 'receivedEventId'

  const eventIds = getState().events.log
    .map(l => l[eventCategoryIdField])
    .filter(({ retryDisabled }) => !retryDisabled)

  dispatch({ type: RETRY_FAILED_EVENT_REQUEST, payload: [...eventIds] })

  const response = await retryEvents(selectedType, selectedSubType, [...eventIds])

  if (response.status === 200) {
    addMessage({ type: 'Success', message: 'Event(s) retried successfully.' })(dispatch)
    dispatch({ type: RETRY_FAILED_EVENT_SUCCESS })
  } else {
    addMessage({ type: 'Failure', message: 'Something went wrong when retrying your event(s). Please try again.' })(dispatch)
    dispatch({ type: RETRY_FAILED_EVENT_FAILURE, payload: [...eventIds] })
  }
}