import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Loader from 'src/components/Loader'
import {
    getSalesChannelMappings,
    getSalesChannelMappingStatuses,
    submitSalesChannelMappings,
    fetchCustomers
} from 'src/actions/QuickBooksActions'
import MappingSettings from 'src/components/MappingSettings'
import Utils from 'src/utils/Utils'

import './SalesChannels.scss'

class SalesChannels extends PureComponent {
    generateSections = () => {
        const filterAccounts = (types, subtypes) => Utils.filterAccountsByType(this.props.quickbooks.quickbooksAccounts, types, subtypes)

        return {
                accountMapping: [
            {
                title: 'Sales Income',
                key: 'salesIncomeAccountId',
                documentationId: '1jsEbmtrJZSya6baNXTnDE',
                options: filterAccounts(['INCOME'], ['SalesOfProductIncome'])
            },
            {
                title: 'Income - Discounts Given',
                key: 'incomeDiscountsRefundsGivenAccountId',
                documentationId: '3roSeVMX6YKaCKwOyw26qC',
                options: filterAccounts(['INCOME'], ['DiscountsRefundsGiven'])
            },
            {
                title: 'Channel Fees COGS',
                key: 'servicesAndFeesCogsAccountId',
                documentationId: '61hRlxdeL7WBQYQC19Phcq',
                options: filterAccounts(['COST_OF_GOODS_SOLD'], ['OtherCostsOfServiceCos'])
            },
            {
                title: 'Channel Fees A/P',
                key: 'servicesAndFeesCreditAccountId',
                documentationId: '6WJdrY5WRXLTJNQBks37le',
                options: filterAccounts(['BANK', 'ACCOUNTS_PAYABLE'], ['AccountsPayable', 'Checking', 'Savings'])
            },
            {
                title: 'Vendor',
                key: 'vendorId',
                documentationId: '6ZKh7RqPZfoadyXxSKN1Je',
                options: this.props.quickbooks.quickbooksVendors || []
            },
            {
                title: 'Refunds Bank Account',
                key: 'refundsBankAccountId',
                documentationId: '2w2FC3F6ACsEtzdns4MUK7',
                options: filterAccounts(['BANK', 'OTHER_CURRENT_ASSET'], null)
            },
            {
                title: 'Sales Tax Payable',
                key: 'otherCurrentLiabilitySalesTaxPayableAccountId',
                documentationId: 'NEdOmwPbiUMJLasVNlMeg',
                options: filterAccounts(['OTHER_CURRENT_LIABILITY'], ['SalesTaxPayable', 'GlobalTaxPayable'])
            },
            {
                title: 'Undeposited Funds',
                key: 'otherCurrentAssetUndepositedFundsAccountId',
                documentationId: '6Si0N2JhoBuRFILMpPlLnS',
                options: filterAccounts(['OTHER_CURRENT_ASSET'], ['UndepositedFunds', 'OtherCurrentAssets'])
            },
                // {
                //   title: 'Refund Allowances',
                //   key: 'refundAllowancesAccountId',
                //   documentationId: '3VBeZbRL6ZYzYxStWfcQld',
                //   options: filterAccounts(['INCOME'], ['DiscountsRefundsGiven'])
                // },
            {
                title: 'Refund Tax Allowances',
                key: 'taxRefundAllowancesAccountId',
                documentationId: 'D4lWxYXy6NmDvaCRZA0uV',
                options: filterAccounts(['INCOME', 'OTHER_CURRENT_LIABILITY'], ['DiscountsRefundsGiven', 'SalesTaxPayable'])
            },
            {
                title: 'Accounts Receivable',
                key: 'accountsReceivableAccountId',
                documentationId: '5ZiERu87GMFUEHopP8lJQ6',
                options: filterAccounts(['ACCOUNTS_RECEIVABLE'], null)
            },
            {
                title: 'Gift Card Liability',
                key: 'giftCardOtherCurrentLiabilitiesAccountId',
                documentationId: '2K4MVq6LHHfYeyUtdevRq4',
                options: filterAccounts(['OTHER_CURRENT_LIABILITY'], ['OtherCurrentLiabilities'])
            },
            {
                title: 'Supplies & Materials - COGS',
                key: 'suppliesAndMaterialsCogsAccountId',
                documentationId: '4ZQ1yZqsMvb7JNE29s1YOV',
                options: filterAccounts(['COST_OF_GOODS_SOLD'], ['SuppliesMaterialsCogs'])
            },
            {
                title: 'Income - Service/Fee Income',
                key: 'incomeServiceFeeIncomeAccountId',
                documentationId: '4ABw9Tqu7OmqR8S0xOSEzW',
                options: filterAccounts(['INCOME'], ['ServiceFeeIncome'])
            },
            {
                title: 'Default Customer',
                key: 'customerId',
                documentationId: 'tFSrC8yIC5fyAc7hDQZc2',
                options: [],
                onSearch: fetchCustomers
            }
                ],
            tracking: [
                {
                    title: 'Order Shipped',
                    key: 'orderShippedTrackSetting',
                    documentationId: '47S71JYtgB8aGGOirDGqaX',
                    trackingTypeOptions: [
                        {id: 'SALES_RECEIPT', name: 'Sales Receipt'},
                        {id: 'INVOICE', name: 'Invoice'},
                        {id: 'JOURNAL_ENTRY_RECEIPT', name: 'Journal Entry (Order Paid)'},
                        {id: 'JOURNAL_ENTRY_INVOICE', name: 'Journal Entry (Order Unpaid)'}
                    ],
                    trackingTypeKey: 'orderTrackingType'
                },
                {
                    title: 'Order Returned',
                    key: 'orderReturnedTrackSetting',
                    documentationId: '41LnvUFTm9BTLAH0ulV1TF',
                    trackingTypeOptions: [
                        {id: 'REFUND_RECEIPT', name: 'Refund Receipt'},
                        {id: 'JOURNAL_ENTRY_RECEIPT', name: 'Journal Entry (Order Paid)'}
                    ],
                    trackingTypeKey: 'returnTrackingType'
                },
                {
                    title: 'Fee Created',
                    key: 'salesChannelFeeCreatedTrackSetting',
                    documentationId: '6yF70IBRxR5ZwhHmyIfCaJ'
                }
            ],
            customization: [
                {
                    title: 'Order Shipped',
                    key: 'orderShippedCustomizationSettings',
                    documentationId: '1Dq7kRrMoJpHJonmG07ehO',
                    customizationOptions: [
                        {id: 'ORDER_SHIP_DATE', name: 'Order Ship Date'},
                        {id: 'ORDER_DATE', name: 'Order Date'},
                        {id: 'ORDER_PAYMENT_DATE', name: 'Order Payment Date'},
                        {id: 'ORDER_CREATED_DATE', name: 'Order Created Date'},
                        {id: 'ORDER_SHIPMENT_CREATED_DATE', name: 'Shipment Created Date'},
                        {id: 'ORDER_SHIPMENT_LABEL_CREATED_DATE', name: 'Shipment Label Created Date'}
                    ]
                },
                {
                    title: 'Order Returned',
                    key: 'orderReturnedCustomizationSettings',
                    customizationOptions: [
                        {id: 'ORDER_SHIP_DATE', name: 'Order Ship Date'},
                        {id: 'ORDER_DATE', name: 'Order Date'},
                        {id: 'ORDER_PAYMENT_DATE', name: 'Order Payment Date'},
                        {id: 'ORDER_CREATED_DATE', name: 'Order Created Date'},
                        {id: 'ORDER_SHIPMENT_CREATED_DATE', name: 'Shipment Created Date'},
                        {id: 'ORDER_SHIPMENT_LABEL_CREATED_DATE', name: 'Shipment Label Created Date'},
                        {id: 'RMA_ISSUE_DATE', name: 'RMA Issue Date'}
                    ]
                },
                {
                    title: 'Fee Created',
                    key: 'salesChannelFeeCreatedCustomizationSettings',
                    customizationOptions: 'Fee Date'
                },
            ],
            templates: [
                {
                    title: 'Order Shipped - Journal Entry Order Unpaid',
                    key: 'orderShippedTemplateSettings',
                    idField: 'orderShippedTemplateSettings',
                    templatingValues: [{templateDisplayValue: 'Buyer Name', templateInputValue: '{{buyerName}}'},
                        {templateDisplayValue: 'Buyer Email', templateInputValue: '{{buyerEmail}}'},
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Ship Address Line 1', templateInputValue: '{{shipAddressLine1}}'},
                        {templateDisplayValue: 'Ship Address Line 2', templateInputValue: '{{shipAddressLine2}}'},
                        {templateDisplayValue: 'Ship Address Line 3', templateInputValue: '{{shipAddressLine3}}'},
                        {templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}'},
                        {templateDisplayValue: 'City', templateInputValue: '{{city}}'},
                        {templateDisplayValue: 'State/Province', templateInputValue: '{{stateProvince}}'},
                        {templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}'}
                    ],
                    lines: [
                        {id: "ORDER_JOURNAL_ENTRY_UNPAID_PENDING_AMOUNT", name: "Pending Payment Amount"},
                        {id: "ORDER_JOURNAL_ENTRY_UNPAID_PRODUCT_SALES", name: "Product Sales"},
                        {id: "ORDER_JOURNAL_ENTRY_UNPAID_SHIPPING", name: "Shipping Paid By Buyer "},
                        {id: "ORDER_JOURNAL_ENTRY_UNPAID_SALES_TAX", name: "Sales Tax "},
                        {id: "ORDER_JOURNAL_ENTRY_UNPAID_DISCOUNTS", name: "Discounts"}
                    ]
                },
                {
                    title: 'Order Shipped - Journal Entry Order Paid',
                    key: 'orderShippedTemplateSettings',
                    idField: 'orderShippedTemplateSettings',
                    templatingValues: [
                        {templateDisplayValue: 'Buyer Name', templateInputValue: '{{buyerName}}'},
                        {templateDisplayValue: 'Buyer Email', templateInputValue: '{{buyerEmail}}'},
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Ship Address Line 1', templateInputValue: '{{shipAddressLine1}}'},
                        {templateDisplayValue: 'Ship Address Line 2', templateInputValue: '{{shipAddressLine2}}'},
                        {templateDisplayValue: 'Ship Address Line 3', templateInputValue: '{{shipAddressLine3}}'},
                        {templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}'},
                        {templateDisplayValue: 'City', templateInputValue: '{{city}}'},
                        {templateDisplayValue: 'State/Province', templateInputValue: '{{stateProvince}}'},
                        {templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}'}
                    ],
                    lines: [
                        {id: "ORDER_JOURNAL_ENTRY_PAID_AMOUNT", name: "Payment Amount"},
                        {id: "ORDER_JOURNAL_ENTRY_PAID_PRODUCT_SALES", name: "Product Sales"},
                        {id: "ORDER_JOURNAL_ENTRY_PAID_SHIPPING", name: "Shipping Paid By Buyer"},
                        {id: "ORDER_JOURNAL_ENTRY_PAID_SALES_TAX", name: "Sales Tax"},
                        {id: "ORDER_JOURNAL_ENTRY_PAID_DISCOUNTS", name: "Discounts"}
                    ]
                },
                {
                    title: 'Order Shipped - Sales Receipt',
                    key: 'orderShippedTemplateSettings',
                    idField: 'orderShippedTemplateSettings',
                    templatingValues: [
                        {templateDisplayValue: 'Buyer Name', templateInputValue: '{{buyerName}}'},
                        {templateDisplayValue: 'Buyer Email', templateInputValue: '{{buyerEmail}}'},
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Ship Address Line 1', templateInputValue: '{{shipAddressLine1}}'},
                        {templateDisplayValue: 'Ship Address Line 2', templateInputValue: '{{shipAddressLine2}}'},
                        {templateDisplayValue: 'Ship Address Line 3', templateInputValue: '{{shipAddressLine3}}'},
                        {templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}'},
                        {templateDisplayValue: 'City', templateInputValue: '{{city}}'},
                        {templateDisplayValue: 'State/Province', templateInputValue: '{{stateProvince}}'},
                        {templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}'},
                        {templateDisplayValue: 'Sku', templateInputValue: '{{countryCode}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Item Quantity', templateInputValue: '{{itemQuantity}}'}
                    ],
                    lines: [
                        {id: "SALES_RECEIPT_ITEM_LINE", name: "Item Lines"},
                        {id: "SALES_RECEIPT_TAX", name: "Tax"},
                        {id: "SALES_RECEIPT_DISCOUNT", name: "Discount"},
                        {id: "SALES_RECEIPT_SHIPPING_COST", name: "Shipping Cost"}
                    ]
                },
                {
                    title: 'Order Shipped - Invoice',
                    key: 'orderShippedTemplateSettings',
                    idField: 'orderShippedTemplateSettings',
                    templatingValues: [
                        {templateDisplayValue: 'Buyer Name', templateInputValue: '{{buyerName}}'},
                        {templateDisplayValue: 'Buyer Email', templateInputValue: '{{buyerEmail}}'},
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Ship Address Line 1', templateInputValue: '{{shipAddressLine1}}'},
                        {templateDisplayValue: 'Ship Address Line 2', templateInputValue: '{{shipAddressLine2}}'},
                        {templateDisplayValue: 'Ship Address Line 3', templateInputValue: '{{shipAddressLine3}}'},
                        {templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}'},
                        {templateDisplayValue: 'City', templateInputValue: '{{city}}'},
                        {templateDisplayValue: 'State/Province', templateInputValue: '{{stateProvince}}'},
                        {templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}'},
                        {templateDisplayValue: 'Sku', templateInputValue: '{{countryCode}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Item Quantity', templateInputValue: '{{itemQuantity}}'}],
                    lines: [
                        {id: "INVOICE_ITEM_LINE", name: "Item Lines"},
                        {id: "INVOICE_TAX", name: "Tax"},
                        {id: "INVOICE_DISCOUNT", name: "Discount"},
                        {id: "INVOICE_SHIPPING_COST", name: "Shipping Cost"}
                    ]
                },
                {
                    title: 'Order Returned - Journal Entry Order Paid',
                    key: 'orderReturnedTemplateSettings',
                    idField: 'orderReturnedTemplateSettings',
                    templatingValues: [
                        {templateDisplayValue: 'Buyer Name', templateInputValue: '{{buyerName}}'},
                        {templateDisplayValue: 'Buyer Email', templateInputValue: '{{buyerEmail}}'},
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Ship Address Line 1', templateInputValue: '{{shipAddressLine1}}'},
                        {templateDisplayValue: 'Ship Address Line 2', templateInputValue: '{{shipAddressLine2}}'},
                        {templateDisplayValue: 'Ship Address Line 3', templateInputValue: '{{shipAddressLine3}}'},
                        {templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}'},
                        {templateDisplayValue: 'City', templateInputValue: '{{city}}'},
                        {templateDisplayValue: 'State/Province', templateInputValue: '{{stateProvince}}'},
                        {templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}'},
                        {templateDisplayValue: 'RMA Number', templateInputValue: '{{rmaNumber}}'},
                        {templateDisplayValue: 'Forward To Email', templateInputValue: '{{forwardToEmail}}'},
                        {templateDisplayValue: 'Notes', templateInputValue: '{{notes}}'}
                    ],
                    lines: [
                        {id: "RMA_JOURNAL_ENTRY_PAID_REFUND_AMOUNT", name: "Refunded Amount"},
                        {id: "RMA_JOURNAL_ENTRY_PAID_PRODUCT_SALES", name: "Refunded Product Sales"},
                        {id: "RMA_JOURNAL_ENTRY_PAID_SHIPPING", name: "Refunded Shipping Paid By Buyer"},
                        {id: "RMA_JOURNAL_ENTRY_PAID_SALES_TAX", name: "Refunded Sales Tax"},
                        {id: "RMA_JOURNAL_ENTRY_PAID_DISCOUNTS", name: "Discounts"}
                    ]
                },
                {
                    title: 'Order Returned - Refund Receipt',
                    key: 'orderReturnedTemplateSettings',
                    idField: 'orderReturnedTemplateSettings',
                    templatingValues: [
                        {templateDisplayValue: 'Buyer Name', templateInputValue: '{{buyerName}}'},
                        {templateDisplayValue: 'Buyer Email', templateInputValue: '{{buyerEmail}}'},
                        {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Ship Address Line 1', templateInputValue: '{{shipAddressLine1}}'},
                        {templateDisplayValue: 'Ship Address Line 2', templateInputValue: '{{shipAddressLine2}}'},
                        {templateDisplayValue: 'Ship Address Line 3', templateInputValue: '{{shipAddressLine3}}'},
                        {templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}'},
                        {templateDisplayValue: 'City', templateInputValue: '{{city}}'},
                        {templateDisplayValue: 'State/Province', templateInputValue: '{{stateProvince}}'},
                        {templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}'},
                        {templateDisplayValue: 'RMA Number', templateInputValue: '{{rmaNumber}}'},
                        {templateDisplayValue: 'Forward To Email', templateInputValue: '{{forwardToEmail}}'},
                        {templateDisplayValue: 'Notes', templateInputValue: '{{notes}}'},
                        {templateDisplayValue: 'Sku', templateInputValue: '{{countryCode}}'},
                        {templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}'},
                        {templateDisplayValue: 'Item Quantity', templateInputValue: '{{itemQuantity}}'},
                        {templateDisplayValue: 'Rma Item Description', templateInputValue: '{{itemDescription}}'}
                    ],
                    lines: [
                        {id: "REFUND_RECEIPT_ITEM_LINE", name: "Item Lines"},
                        {id: "REFUND_RECEIPT_TAX", name: "Tax"},
                        {id: "REFUND_RECEIPT_DISCOUNT", name: "Discount"},
                        {id: "REFUND_RECEIPT_SHIPPING_COST", name: "Shipping Cost"}
                    ]
                },
                {
                    title: 'Fee Created',
                    key: 'salesChannelFeeCreatedTemplateSettings',
                    idField: 'salesChannelFeeCreatedTemplateSettings',
                    templatingValues: [
                        {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
                        {templateDisplayValue: 'Fee Type', templateInputValue: '{{feeType}}'},
                        {templateDisplayValue: 'Fee Description', templateInputValue: '{{feeDescription}}'}
                    ],
                    lines: [
                        {id: "CREDITS", name: "Credit Line"},
                        {id: "DEBITS", name: "Debit Line"}
                    ]
                }
            ]
    
        }
    }

    render() {
        const {quickbooks} = this.props
        const headerRow = [
            'Sales Channel', 'Sales Income', 'Channel Fees', 'Channel Fees Credit', 'Refund Bank Account',
            'Sales Tax Payable', 'Accounts Receivable', 'Actions'
        ]

        const displayRows = [
            'salesIncomeAccountId', 'servicesAndFeesCogsAccountId', 'servicesAndFeesCreditAccountId',
            'refundsBankAccountId', 'otherCurrentLiabilitySalesTaxPayableAccountId',
            'accountsReceivableAccountId'
        ]

        if (!quickbooks.quickbooksAccounts.length > 0) return <Loader/>

        return <MappingSettings
            title='Sales Channels'
            documentationId='JDwj8zceWXyZb5M7bR4qF'
            headerRow={headerRow}
            displayRows={displayRows}
            idField='salesChannelId'
            getMappings={getSalesChannelMappings}
            getMappingStatuses={getSalesChannelMappingStatuses}
            submitMapping={submitSalesChannelMappings}
            quickbooks={quickbooks}
            generateSections={this.generateSections}
        />
    }
}

export default connect(({quickbooks}) => ({quickbooks}), null)(SalesChannels)