import { APPLICATION_INIT, USER_LOGIN, USER_LOGOUT, } from 'src/actions/UserActions'

const checkAuthStatus = () => {
  const authString = localStorage.getItem('skubana:auth')
  // TODO AUTH LOGIC HERE AT SOME POINT
  return authString !== null && authString.length > 0; // !expired,
}
const initialState = { authenticated: checkAuthStatus() }

export default (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_INIT:
      return { ...state, authenticated: checkAuthStatus() }

    case USER_LOGIN:
      localStorage.setItem('skubana:auth', action.payload.code)
      localStorage.setItem('skubana:authdate', new Date())

      return { ...state, authenticated: true }

    case USER_LOGOUT:
      window.localStorage.logout = true
      window.localStorage.clear()
      window.location = '/'

      return { ...state, authenticated: false }

    default:
      return state
  }
}
