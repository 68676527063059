import {
  DEPENDENCIES_HEALTH_CHECK_FAILURE,
  DEPENDENCIES_HEALTH_CHECK_REQUEST,
  DEPENDENCIES_HEALTH_CHECK_SUCCESS,
  EVENT_SUMMARY_FAILURE,
  EVENT_SUMMARY_REQUEST,
  EVENT_SUMMARY_SUCCESS,
  MAPPING_HEALTH_CHECK_FAILURE,
  MAPPING_HEALTH_CHECK_REQUEST,
  MAPPING_HEALTH_CHECK_SUCCESS
} from 'src/actions/HealthActions'


const initialState = {
  dependencies: {
    products: { loading: true, data: [] },
    taxitems: { loading: true, data: [] },
    shippingserviceitems: { loading: true, data: [] },
    vendors: { loading: true, data: [] },
    customers: { loading: true, data: [] },
    loaded: false
  },
  mappings: {
    loading: false,
    data: []
  },
  events: {
    loading: true,
    messages: [],
    data: {}
  }
}

export default (state = initialState, { payload, type }) => {
  switch(type) {
    case MAPPING_HEALTH_CHECK_REQUEST: {
      return { ...state, mappings: { loading: true } }
    }
    case MAPPING_HEALTH_CHECK_SUCCESS: {
      const humanReadableType = {
        INVENTORY_VALUE_CHANGE: 'Inventory Value Change',
        ORDER_SHIPPED: 'Order Shipped',
        ORDER_RETURNED: 'Order Returned',
        SHIPMENT_VOIDED: 'Shipment Voided',
        PO_ITEM_RECEIVED: 'PO Item Received',
        PO_ITEM_MODIFIED: 'PO Item Modified',
        MARKETPLACE_INBOUND_SHIPMENT_RECEIVED: 'Marketplace Inbound Shipment Received',
        MARKETPLACE_INBOUND_SHIPMENT_SHIPPED: 'Marketplace Inbound Shipment Shipped',
        IN_TRANSIT_MARKETPLACE_INBOUND_SHIPMENT_ADJUST: 'In-Transit Marketplace Inbound Shipment Adjust',
        STO_ITEM_RECEIVED: 'STO Item Received',
        STO_ITEM_SHIPPED: 'STO Item Shipped',
        IN_TRANSIT_STO_ITEM_ADJUST: 'In-Transit STO Item Adjust',
        ADD_INVENTORY: 'Add Inventory',
        REMOVE_INVENTORY: 'Remove Inventory',
        PO_ITEM_MODIFIED_SHIPPED_COGS_ADJUST: 'PO Item Modified Shipped COGS Adjust',
        ORDER: 'Order',
        RETURN: 'Return',
        SALES_CHANNEL_FEES: 'Sales Channel Fees',
        SHIPMENTS_PROVIDER: 'Shipments Provider',
        SHIPMENTS_CARRIER: 'Shipments Carrier',
        null: 'N/A'
      }

      const data = payload.map(({type, subType, ...p}) => ({
        ...p,
        type: humanReadableType[type],
        subType: humanReadableType[subType]
      }))

      return { ...state, mappings: { loading: false, data } }
    }
    case MAPPING_HEALTH_CHECK_FAILURE: {
      return { ...state, mappings: { loading: false } }
    }
    case DEPENDENCIES_HEALTH_CHECK_REQUEST: {
      return { ...state, dependencies: {...state.dependencies, [payload]: { loading: true } } }
    }
    case DEPENDENCIES_HEALTH_CHECK_SUCCESS: {
      return {
        ...state,
        dependencies: {
          ...state.dependencies,
          [payload.type]: { data: payload.value, loading: false },
          loaded: true
        }
      }
    }
    case DEPENDENCIES_HEALTH_CHECK_FAILURE: {
      return {
        ...state,
        dependencies: {
          ...state.dependencies,
          [payload.type]: { loading: false }
        }
      }
    }
    case EVENT_SUMMARY_REQUEST: {
      return { ...state, events: { loading: true } }
    }
    case EVENT_SUMMARY_SUCCESS: {
      const data = payload.eventSummaryDtos.reduce((acc, {
        daysBack,
        receivedIgnoredByCustomer,
        receivedMissingMapping,
        receivedPending,
        receivedProcessed,
        syncedFailed,
        syncedProcessed
      }) => {
        return {
          ...acc,
          ignored: { ...acc.ignored, [daysBack]: (acc.ignored[daysBack] || 0) + receivedIgnoredByCustomer },
          missingMapping: { ...acc.missingMapping, [daysBack]: (acc.missingMapping[daysBack] || 0) + receivedMissingMapping },
          pending: { ...acc.pending, [daysBack]: (acc.pending[daysBack] || 0) + receivedPending },
          processed: { ...acc.processed, [daysBack]: (acc.processed[daysBack] || 0) + receivedProcessed },
          failed: { ...acc.failed, [daysBack]: (acc.failed[daysBack] || 0) + syncedFailed }
        }
      }, { ignored: {}, missingMapping: {}, pending: {}, processed: {}, failed: {} })

      return { ...state, events: { data, messages: payload.messages, loading: false } }
    }
    case EVENT_SUMMARY_FAILURE: {
      return { ...state, events: { loading: false } }
    }
    default:
      return state
  }
}