import {
  GET_WEBHOOKS,
  GET_WEBHOOKS_SUCCESS,
  GET_WEBHOOKS_FAILURE,
  CREATE_WEBHOOKS,
  CREATE_WEBHOOKS_SUCCESS,
  CREATE_WEBHOOKS_FAILURE
} from 'src/actions/WebhooksActions'

const initialState = {
  loading: false,
  apiFailure: false,
  webhooks: []
}

export default (state = initialState, action) => {
  switch(action.type) {
    case CREATE_WEBHOOKS:
    case GET_WEBHOOKS: {
      return { ...state, loading: true }
    }
    case GET_WEBHOOKS_SUCCESS: {
      return { ...state, webhooks: action.payload, loading: false }
    }
    case CREATE_WEBHOOKS_FAILURE:
    case GET_WEBHOOKS_FAILURE: {
      return { ...state, apiFailure: true, loading: false }
    }
    case CREATE_WEBHOOKS_SUCCESS: {
      const webhooks = state.webhooks.map(w => {
        const created = action.webhooks.find(wh => wh.webhookType === w.webhookType && wh.webhookSubType === w.eventType )

        if (created) {
          return { ...w, id: true, created: new Date() }
        }

        return { ...w }
      })

      return { ...state, webhooks, loading: false }
    }
    default:
      return state
  }
}