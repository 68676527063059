import React, { Component } from 'react'
import { Card } from 'cargo'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import ToggleSwitch from '../../components/ToggleSwitch'
import ToggleSwitchWithLabel from '../../components/ToggleSwitchWithLabel'
import SelectWithLabel from "../../components/SelectWithLabel";

library.add(faCheckCircle, faTimesCircle)

export default class QuickBooksSettings extends Component {
  state = { ...this.props.settings }

  handleSave = () => this.props.handleSubmit({
    enabled: this.state.enabled,
    trackCogsBySalesChannel: this.state.trackCogsBySalesChannel,
    reconcileCustomersByEmail: this.state.reconcileCustomersByEmail,
    reconcileVendorsByEmail: this.state.reconcileVendorsByEmail,
    reconcileProductsBySku: this.state.reconcileProductsBySku,
    eventFailureNotificationInterval: this.state.eventFailureNotificationInterval,
    notificationEmail: this.state.notificationEmail
  })

  eventFailureNotificationIntervalSettings = [
    {id: 0, name: 'Never'},
    {id: 1, name: 'Daily'},
    {id: 7, name: 'Weekly'},
    {id: 14, name: 'Bi-Weekly'},
    {id: 30, name: 'Monthly'}];

  render() {
    const { enabled, settings: { loading } } = this.props

    return (
      <div className={`${enabled ? '' : 'disabled'} row`}>
        <div className='col-xl-6'>
          {loading ? 'Loading' : (
            <Card>
              <h4 className='header-title'>Your QuickBooks Settings</h4>
              <div className='mt-3'>
                <ToggleSwitchWithLabel
                  enabled={ this.state.enabled }
                  onChange={ ({target: { value: enabled }}) => this.setState({ enabled }) }
                  labelText='Skubana / QBO Data Flow Enabled'
                />
              </div>
              <div className='mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ display: 'inline-block', margin: 0 }}>Track COGS by Warehouse</p>
                <div style={{ display: 'inline-block', margin: '0 10px' }}>
                  <ToggleSwitch
                    enabled={ this.state.trackCogsBySalesChannel }
                    onChange={ ({target: { value: trackCogsBySalesChannel }}) => this.setState({ trackCogsBySalesChannel }) }
                  />
                </div>
                <p style={{ display: 'inline-block',  margin: 0 }}>Track COGS by Sales Channel</p>
              </div>
              <div className='mt-3'>
                <ToggleSwitchWithLabel
                  enabled={ this.state.reconcileCustomersByEmail }
                  onChange={ ({target: { value: reconcileCustomersByEmail }}) => this.setState({ reconcileCustomersByEmail }) }
                  labelText='Reconcile QBO Customers by Email'
                />
              </div>
              <div className='mt-3'>
                <ToggleSwitchWithLabel
                  enabled={ this.state.reconcileProductsBySku }
                  onChange={ ({target: { value: reconcileProductsBySku }}) => this.setState({ reconcileProductsBySku }) }
                  labelText='Reconcile QBO Products by SKU'
                />
              </div>
              <div className='mt-3'>
                <ToggleSwitchWithLabel
                  enabled={ this.state.reconcileVendorsByEmail }
                  onChange={ ({target: { value: reconcileVendorsByEmail }}) => this.setState({ reconcileVendorsByEmail }) }
                  labelText='Reconcile QBO Vendors by Contact Email'
                />
              </div>
              <div className='mt-3'>
                <SelectWithLabel
                    title="Email Notification Frequency"
                    options={ this.eventFailureNotificationIntervalSettings }
                    onChange={ ({target: { value: eventFailureNotificationInterval }}) => this.setState({ eventFailureNotificationInterval }) }
                    selected={ this.state.eventFailureNotificationInterval}
                />
              </div>

              <div className='mt-3'>
                <div className='SelectContainer'>
                  <label htmlFor="notificationEmail">Notification Email</label>
                  <input value={this.state.notificationEmail} style={{height: '40px', width: '50%', padding: '10px'}}
                         type='text' placeholder='Emails' id="notificationEmail"
                         onChange={({target: {value: notificationEmail}}) => this.setState({notificationEmail})}/>
                </div>
              </div>
              <div className='mt-3'>
                <button className='btn btn-primary pull-right' onClick={ this.handleSave }>Submit</button>
              </div>
            </Card>
          )}
        </div>
      </div>
    )
  }
}
