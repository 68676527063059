import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronRight,
  faCogs,
  faMapSigns,
  faProjectDiagram,
  faShippingFast,
  faWarehouse,
  faCode,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom"
import { connect } from 'react-redux'
import logo from 'src/images/gotham_works_logo.svg'
import logo_sm from 'src/images/circle_logo.svg'
import './LeftSideBar.scss'

library.add(
  faChevronRight,
  faCogs,
  faMapSigns,
  faProjectDiagram,
  faShippingFast,
  faWarehouse,
  faCode,
  faLayerGroup
)

const authenticatedLinks = {
  'Warehouses': ['/admin/mappings/warehouses', 'warehouse'],
  'Sales Channels': ['/admin/mappings/saleschannels', 'project-diagram'],
  'Shipping Providers': ['/admin/mappings/shippingproviders', 'shipping-fast'],
  'Shipping Carriers': ['/admin/mappings/shippingcarriers', 'shipping-fast'],
  'Global Mappings': ['/admin/mappings/defaults', 'map-signs'],
  'Webhooks': ['/admin/webhooks', 'code'],
  'Events': ['/admin/events', 'layer-group'],
  'Health': ['/admin/health', 'heartbeat'],
  'Account Settings': ['/admin', 'cogs']
}

class LeftSideBar extends PureComponent {
  getLinks = () => {
    return (
      <>
        <li className='side-nav-title side-nav-item'>Navigation</li>
        {Object.entries(authenticatedLinks).map(([key, [link, icon]], index) => (
          <li key={ index } className='side-nav-item'>
            <NavLink exact to={ link } className='side-nav-link'>
              <i><FontAwesomeIcon icon={ icon }/></i>
              <span>{ key }</span>
            </NavLink>
          </li>
        ))}
      </>
    )
  }

  render() {
    const { condensed } = this.props

    return (
      <div className='left-side-menu mm-active' id='left-side-bar'>
        <div className='slimscroll-menu' id='left-side-menu-container'>
          {condensed
            ? <a href='/' className='logo mt-3 mb-1 logo-sm text-center' dangerouslySetInnerHTML={{ __html: logo_sm }} />
            : <a href='/' className='logo mt-3 mb-3 logo-lg text-center' dangerouslySetInnerHTML={{ __html: logo }} />
          }
          <ul className='metismenu side-nav' id='left-bar-menu'>
            {this.props.user.authenticated && this.getLinks()}
          </ul>
          <div className='clearfix' />
        </div>
      </div>
    )
  }
}


export default connect(({ user }) => ({ user }))(LeftSideBar)
