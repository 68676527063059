export const ADD_MESSAGES = 'ADD_MESSAGES'
export const DISMISS_MESSAGES = 'DISMISS_MESSAGES'

let id = 0

export const addMessage = message => dispatch => {
  message.id = id++
  dispatch({ type: ADD_MESSAGES, payload: message })
}

export const removeMessage = message => dispatch => dispatch({ type: DISMISS_MESSAGES, payload: message })