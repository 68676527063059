import React from 'react'

import SelectWithLabel from './SelectWithLabel'
import SearchableSelectWithLabel from './SearchableSelectWithLabel'
import './MappingGrid.scss'

const MappingGrid = ({ fields, mappingRef, handleMappingChange }) => {
  // We do this because otherwise the spacing in the grid will be weird. We could probably fix
  // the issue with CSS too but this should be fine for now
  const placeholders = new Array(3 - (fields.length % 3)).fill(<div />)

  return (
    <div className='Section'>
      <div className='SectionHeader'>
        <p className='SectionTitle'>Account Mapping</p>
      </div>
      <div className='Flex3ColSpread'>
        {fields.map(({ title, key, options, documentationId, enabled = true, onSearch }) => {
          if (key === 'customerId') {
            return (
              <SearchableSelectWithLabel
                key={ key }
                title={ title }
                options={ options }
                selected={ mappingRef[key] }
                onChange={ handleMappingChange(key) }
                onSearch={ onSearch(mappingRef[key]) }
                disabled={ !enabled }
                documentationId={ documentationId }
                defaultName={ mappingRef.defaultMapping ? 'Select...' : 'Use Default' }
              />
            )
          }

          return (
            <SelectWithLabel
              key={ key }
              title={ title }
              options={ options }
              selected={ mappingRef[key] }
              onChange={ handleMappingChange(key) }
              disabled={ !enabled }
              documentationId={ documentationId }
              defaultName={ mappingRef.defaultMapping ? 'Select...' : 'Use Default' }
            />
          )}
        )}

        { placeholders }
      </div>
    </div>
  )
}

export default MappingGrid