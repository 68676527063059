export default class Utils {
  static getQueryParams = searchString => {
    if (!searchString) return {}

    return searchString.substring(1) //remove ? from beginning
      .split('&')
      .map(line => line.trim().split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  }

  static redirectToSkubana = () => {
    const skubanaUrl = process.env.REACT_APP_SKUBANA_APP_URL
    const appUrl = process.env.REACT_APP_APP_URL;
    window.location.href = `${skubanaUrl}/work/dashboard?redirect=${encodeURI(appUrl)}`
  }

  static redirectToAdmin = () => {
    const appUrl = process.env.REACT_APP_APP_URL;
    window.location.href = `${appUrl}/admin`
  }

  static getAppStoreUrl = () => {
    const { REACT_APP_SKUBANA_APP_URL, REACT_APP_SKUBANA_APP_PATH } = process.env

    return `${REACT_APP_SKUBANA_APP_URL}${REACT_APP_SKUBANA_APP_PATH}`

  }

  static filterAccountsByType = (accounts, types, subtypes) => accounts
    .filter(({ accountType, accountSubType }) => types.includes(accountType) && (!subtypes || subtypes.includes(accountSubType)))
    .map(account => ({...account}))

  static findSelectedId = (idToFind = null, listOfObjects = []) => listOfObjects?.find(account => account?.id === idToFind)?.id

  static makeApiCall = (endpointPath, method = 'GET', body, queryParams) => {
    const baseUrl = process.env.REACT_APP_API_URL
    const queryString = queryParams ? '&' + queryParams : ''
    const headers = {
      'Authorization': localStorage.getItem('skubana:auth')
    }

    if (body) headers['Content-Type'] = 'application/json'

    return fetch(baseUrl + endpointPath + (queryString ? '?' + queryString : ''), {
      method,
      body: JSON.stringify(body),
      headers
    })
  }

  static longNameShort = (name, maxLength) => {
    if (name.length > maxLength) {
      name = name.substring(0, (maxLength - 3))
      name += '...'
    }

    return name
  }
  static checkIfJWTIsPresent = () => {
    const skubanaUrl = process.env.REACT_APP_SKUBANA_APP_URL
    const appUrl = process.env.REACT_APP_APP_URL;
    const searchParam = window.location.search

    if (searchParam && searchParam.includes("jwt")) {
      // if jwt exists get jwt value and redirect to skubana version 1.0
      const jwt = searchParam.substring(searchParam.indexOf('=') + 1);
      fetch(`${skubanaUrl}/loginjwt?jwt=${jwt}&redirect=${encodeURI(appUrl)}`, {
        method: `GET`,
        headers: {
          'redir': `${encodeURI(appUrl)}`
        }
      }).then((res)=>{
        if(res.ok) {
          return res.json();
        }
      }).then((res)=>{
        console.log(res);
      })
    }
  }
}
