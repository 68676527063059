import React from 'react'
import Select from 'src/components/Select'
import DocumentedLabel from 'src/components/DocumentedLabel'

import 'src/components/SelectWithLabel.scss'

export default ({ title, options, onChange, selected, documentationId, defaultName = 'Use Default' }) => {
  const modifiedOptions = options.map(o => ({ ...o }))
  if (modifiedOptions.length === 0 || modifiedOptions[0].id !== null) {
    modifiedOptions.unshift({ id: null, name: defaultName })
  }

  return (
    <div className='SelectContainer'>
      <DocumentedLabel documentationId={ documentationId }>
        { title }
      </DocumentedLabel>
      <Select options={ modifiedOptions } onChange={ onChange } selected={ selected } />
    </div>
  )
}