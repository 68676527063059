import Utils from 'src/utils/Utils'

export const GET_WEBHOOKS = 'GET_WEBHOOKS'
export const GET_WEBHOOKS_SUCCESS = 'GET_WEBHOOKS_SUCCESS'
export const GET_WEBHOOKS_FAILURE = 'GET_WEBHOOKS_FAILURE'
export const CREATE_WEBHOOKS = 'CREATE_WEBHOOKS'
export const CREATE_WEBHOOKS_SUCCESS = 'CREATE_WEBHOOKS_SUCCESS'
export const CREATE_WEBHOOKS_FAILURE = 'CREATE_WEBHOOKS_FAILURE'

export const getWebhooks = () => async dispatch => {
  dispatch({ type: GET_WEBHOOKS })

  const webhooksApiCall = await Utils.makeApiCall('/webhooks/manage')

  if (webhooksApiCall.status !== 200) {
    return dispatch({ type: GET_WEBHOOKS_FAILURE, reason: webhooksApiCall.error })
  }

  const response = await webhooksApiCall.json()
  dispatch({ type: GET_WEBHOOKS_SUCCESS, payload: response })
}

export const createWebhooks = webhooks => async dispatch => {
  if (!webhooks.length) return

  dispatch({ type: CREATE_WEBHOOKS })

  const createWebhookApiCall = await Utils.makeApiCall('/webhooks/manage', 'PUT', webhooks)

  if (createWebhookApiCall.status !== 201) {
    return dispatch({ type: CREATE_WEBHOOKS_FAILURE, reason: createWebhookApiCall.error })
  }

  dispatch({ type: CREATE_WEBHOOKS_SUCCESS, webhooks })
}