import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { CollapsibleCard, Card, Table } from 'cargo'
import {
  getDependencyHealthStatuses,
  getMappingHealthStatuses,
  getEventSummary
} from 'src/actions/HealthActions'
import './HealthDashboard.scss'

class HealthDashboard extends PureComponent {
  componentDidMount() {
    this.props.getEventSummary()
  }

  render() {
    const {
      dependencies: {
        products,
        taxitems,
        shippingserviceitems,
        vendors,
        customers,
        loaded: dependenciesLoaded
      },
      mappings,
      events
    } = this.props.health

    return (
      <div className='HealthDashboard'>
        <Card style={{ marginTop: 20 }}>
          <p>Event Summary</p>
          <div className='EventSummaryContainer'>
            {events.loading ? 'Loading...' : ([
              ['Processed', events.data.processed],
              ['Pending', events.data.pending],
              ['Failed', events.data.failed],
              ['Missing Mapping', events.data.missingMapping],
              ['Ignored', events.data.ignored]
            ].map(([name, counts]) => (
              <div className='EventSummaryBox'>
                <p>{ name }</p>
                <div className='EventCounts'>
                  <div className='EventCount'>
                    <p className='Count' data-count={counts[0]}>{counts[0]}</p>
                    <p>All Time</p>
                  </div>
                  <div className='EventCount'>
                    <p className='Count' data-count={counts[1]}>{counts[1]}</p>
                    <p>1 Day</p>
                  </div>
                  <div className='EventCount'>
                    <p className='Count' data-count={counts[3]}>{counts[3]}</p>
                    <p>3 Days</p>
                  </div>
                  <div className='EventCount'>
                    <p className='Count' data-count={counts[7]}>{counts[7]}</p>
                    <p>7 Days</p>
                  </div>
                </div>
              </div>
            )))}
          </div>
          <div>
            <p>Messages</p>
            {events.loading ? 'Loading...' : (
              <ul>
                { events.messages.map(m => <li>{ m }</li>) }
              </ul>
            )}
          </div>
        </Card>
        <CollapsibleCard
          title='Dependencies Health Check'
          style={{ marginTop: 20 }}
          collapsed
          onClick={ () => !dependenciesLoaded && this.props.getDependencyHealthStatuses() }
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {[
              ['Products', products],
              ['Tax Items', taxitems],
              ['Shipping Service Items', shippingserviceitems],
              ['Vendors', vendors],
              ['Customers', customers]
            ].map(([name, { loading, data }]) => (
              <div style={{ flex: '0 0 49%', marginBottom: 10, overflowX: 'scroll' }}>
                <p style={{ marginBottom: 0 }}>{ name }</p>
                <div style={{ height: 200 }}>
                  <Table
                    data={ loading ? [] : data }
                    emptyText={ loading ? 'Loading...' : 'No Issues' }
                    mapping={[
                      { column: 'Name', content: ({ name }) => name },
                      { column: 'Issues', content: ({ messages }) => messages.join('. ') }
                    ]}
                    style={{ width: '100%' }}
                    growWithContainer={ true }
                  />
                </div>
              </div>
            ))}
            <div style={{ flex: '0 0 49%' }}>&nbsp;</div>
          </div>
        </CollapsibleCard>
        <CollapsibleCard
          title='Mapping Status Overview'
          style={{ marginTop: 20 }}
          collapsed
          onClick={ () => !mappings.data.length && this.props.getMappingHealthStatuses() }
        >
          <div>
            <p style={{ marginBottom: 0, marginTop: 10 }}>
              Errors &nbsp;
              <span style={{ color: 'red' }}>
                { mappings.loading ? '' : mappings.data.filter(d => d.errorMessages.length).length }
              </span>
            </p>
            <div style={{ height: 300 }}>
              <Table
                data={ mappings.loading ? [] : mappings.data.filter(d => d.errorMessages.length) }
                emptyText={ mappings.loading ? 'Loading...' : 'No data' }
                growWithContainer={ true }
                mapping={[
                  { column: 'Name', content: ({ name }) => name },
                  { column: 'Type', content: ({ type }) => type },
                  { column: 'SubType', content: ({ subType }) => subType || 'N/A' },
                  { column: 'Messages', content: ({ errorMessages }) => errorMessages.join('. ') }
                ]}
              />
            </div>
          </div>
          <div>
            <p
              style={{ marginBottom: 0, marginTop: 10 }}>
              Warnings &nbsp;
              <span style={{ color: 'orange' }}>
                { mappings.loading ? '' : mappings.data.filter(d => d.warningMessages.length).length }
              </span>
            </p>
            <div style={{ height: 300 }}>
              <Table
                data={ mappings.loading ? [] : mappings.data.filter(d => d.warningMessages.length) }
                emptyText={ mappings.loading ? 'Loading...' : 'No data' }
                growWithContainer={ true }
                mapping={[
                  { column: 'Name', content: ({ name }) => name },
                  { column: 'Type', content: ({ type }) => type },
                  { column: 'SubType', content: ({ subType }) => subType || 'N/A' },
                  { column: 'Messages', content: ({ warningMessages }) => warningMessages.join('. ') }
                ]}
              />
            </div>
          </div>
        </CollapsibleCard>
      </div>
    )
  }
}

export default connect(
  ({ health }) => ({ health }),
  { getDependencyHealthStatuses, getMappingHealthStatuses, getEventSummary }
)(HealthDashboard)