import { ADD_MESSAGES, DISMISS_MESSAGES, } from 'src/actions/ToastActions'

const initialState = { messages: [] }

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case ADD_MESSAGES: {
      const { messages } = state
      return { ...state, ...{ messages: messages.concat([payload]) } }
    }
    case DISMISS_MESSAGES: {
      const { messages } = state
      return { ...state, ...{ messages: messages.filter(e => e.id !== payload) } }
    }
    default:
      return state
  }
}

