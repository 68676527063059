import React from 'react'
import { connect } from 'react-redux'

import { removeMessage } from 'src/actions/ToastActions'

const Toast = ({ messages: { messages }, removeMessage }) => messages.map(({ id, type, message }) => (
  <div
    key={ id }
    role='alert'
    aria-live='assertive'
    aria-atomic='true'
    style={{
      position: 'fixed',
      top: 15,
      right: 15,
      zIndex: 2
    }}>
    <div className='card text-white bg-success mb-3' style={{ maxWidth: '18rem' }}>
      <div className='card-body'>
        <h5 className='card-title'>
          { type }!
          <button
            type='button'
            className='ml-2 mb-1 close'
            data-dismiss='toast'
            aria-label='Close'
            onClick={ () => removeMessage(id) }
            style={{
              position: 'relative',
              top: '-10px'
            }}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </h5>
        <p className='card-text'>{ message }</p>
      </div>
    </div>
  </div>
))

export default connect(
  ({ messages }) => ({ messages }),
  { removeMessage }
)(Toast)