import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'cargo'
import { addMessage } from 'src/actions/ToastActions'
import { saveCustomerSettings, } from 'src/actions/QuickBooksActions'
import PageTitle from 'src/components/PageTitle'
import QuickBooksSettings from 'src/pages/Account/QuickBooksSettings'
import Login from 'src/pages/Login/Login'
import './Account.css'

const AdminAccount = ({
  user: { authenticated },
  settings: { loading, customerSettings },
  addMessage,
  saveCustomerSettings
}) => {
  const handleSubmit = async customerSettings => {
    await saveCustomerSettings(customerSettings)
    addMessage({ type: 'Success', message: 'Your settings have been saved' })
  }

  if (authenticated) {
    return (
      <Card center>
        <PageTitle title="Account Settings" />
        {loading ? 'Loading' : (
          <QuickBooksSettings
            handleSubmit={ handleSubmit }
            settings={ customerSettings }
            enabled
          />
        )}
      </Card>
    )
  }

  return <Login/>
}

export default connect(
  ({ oAuth, user, quickbooks, userSettings: settings }) => ({ oAuth, user, quickbooks, settings }),
  { saveCustomerSettings, addMessage }
)(AdminAccount)
