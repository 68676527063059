import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


import MappingTable from "src/components/MappingTable/MappingTable";
import { Card } from 'cargo'
import Error from 'src/components/Error';

export default class MappingCard extends React.Component {

  static propTypes = {
    loading: PropTypes.bool,
    enabled: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]),
    fetchData: PropTypes.func,
    headers: PropTypes.array,
    rows: PropTypes.array,
    submitForm: PropTypes.func,
  }

  render() {
    const {
      error,
      children,
      headers,
      saving,
      rows,
      enabled,
      submitForm
    } = this.props;

    return (
      <Fragment>
        {error ? <Error/> : (
          <div>
            <div className="mt-4">
              <Card>
                {children}
                <MappingTable
                  headers={headers}
                  rows={rows}
                  enabled={enabled}
                  saving={saving}
                  submitForm={submitForm}
                />
              </Card>
            </div>
          </div>
        )
        }
      </Fragment>
    )
  }
}
