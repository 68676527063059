import Utils from "src/utils/Utils"
import { login } from 'src/actions/UserActions'
import {
  submitQuickbooksAuthCode,
  submitSkubanaAuthCode,
  USER_SETTINGS_UPDATE_QBO_AUTH,
  USER_SETTINGS_UPDATE_SKUBANA_AUTH
} from "src/actions/QuickBooksActions"

export const checkURLStateParams = search => async dispatch => {
  const { sid, code, cid, state, realmId } = Utils.getQueryParams(search)

  if (sid) dispatch(login(decodeURIComponent(sid)))

  if (code && cid) {
    const response = await submitSkubanaAuthCode(code, cid)
    dispatch({ type: USER_SETTINGS_UPDATE_SKUBANA_AUTH, payload: response.ok })
  }

  if (code && state && realmId) {
    const response = await submitQuickbooksAuthCode(code, state, realmId)
    dispatch({ type: USER_SETTINGS_UPDATE_QBO_AUTH, payload: response.ok })
  }
}
