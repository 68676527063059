import React from 'react'
import './PageTitle.scss'


export default ({ title }) => (
  <div className="row">
    <div className="col-12">
      <div className="page-title-box">
        <h4 className="page-title">{ title }</h4>
      </div>
    </div>
  </div>
)