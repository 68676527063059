import React, {PureComponent} from 'react'
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import {connect, ReactReduxContext} from 'react-redux'
import {LayeredModal} from 'cargo'
import Routes from './NavLinks'
import LeftSideBar from "./LeftSideBar"
import TopBar from "./TopBar"
import Toast from 'src/components/Toast'
import Error from 'src/components/Error'
import {checkURLStateParams} from 'src/actions/OAuthActions'
import {getCustomerSettings} from 'src/actions/QuickBooksActions'
import {applicationInit} from 'src/actions/UserActions'
import Loading from 'src/components/Loader';

import './App.scss'

class App extends PureComponent {
  state = {
    navOpen: false,
    mobile: false,
    condensed: false,
    loading: true
  }

  componentWillMount = async () => {
    const { applicationInit, getCustomerSettings, checkURLStateParams } = this.props

    await checkURLStateParams(window.location.search)

    getCustomerSettings()
    applicationInit()
  }

  componentDidMount() {
    this._adjustLayout()
    window.addEventListener("resize", this._adjustLayout)
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._adjustLayout);
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (this.node) this.toggleNav(this.node.contains(e.target))
  }

  toggleNav = navOpen => this.setState({ navOpen })

  _adjustLayout = () => this.setState({
    mobile: window.innerWidth <= 768,
    condensed: window.innerWidth >= 768 && window.innerWidth <= 1028
  })

  render() {
    const {
      settings: { error, loading, quickbooksAuthorized },
      user: { authenticated }
    } = this.props

    if (loading && authenticated) return <Loading />

    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path='/'
            component={() => (
              <PageLayout
                toggleNav={ this.toggleNav }
                quickbooksAuthorized={ quickbooksAuthorized }
                authenticated={ authenticated }
                nodeRef={ this.node }
                { ...this.state }
              />
            )}
          />
          <Route
            path='/admin'
            component={() => (
              <PageLayout
                admin
                toggleNav={ this.toggleNav }
                quickbooksAuthorized={ quickbooksAuthorized }
                authenticated={ authenticated }
                error={ error }
                nodeRef={ this.node }
                { ...this.state }
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}

const PageLayout = ({ authenticated, quickbooksAuthorized, admin, condensed, navOpen, mobile, error, toggleNav, nodeRef }) => {
  let primaryClass = 'wrapper'
  let bodyClass = authenticated && !error ? 'content-page' : ''

  if (admin && authenticated && !quickbooksAuthorized) {
    window.location = '/'
    return
  }

  if (admin) {
    if (mobile) primaryClass += ' mobile'
    if (condensed) primaryClass += ' condensed'
    if (navOpen) primaryClass += ' sidebar-enable'
  } else {
    bodyClass += ' full-width'
  }

  const sideBar = (error, homepage, condensed) => {
    if (error && !homepage) return <Redirect to='/'/>

    if (error) return <div />

    return <LeftSideBar condensed={ condensed } />
  }

  return (
    <div className={ primaryClass }>
      {admin && authenticated &&  (
        <div ref={node => nodeRef = node}>
          { sideBar(error, window.location.pathname === '/', condensed) }
        </div>
      )}
      <div className={ bodyClass }>
        <div className='content'>
          { authenticated && <TopBar admin={ admin } toggleNav={ toggleNav } /> }
          <Toast/>
          {error ? <Error/> : (
            <Switch>
              <Routes admin={ admin } authenticated={ authenticated } quickbooksAuthorized={ quickbooksAuthorized } />
            </Switch>
          )}
        </div>
      </div>
      <LayeredModal context={ ReactReduxContext } />
    </div>
  )
}

export default connect(
  ({ user, userSettings }) => ({ user, settings: userSettings }),
  { applicationInit, checkURLStateParams, getCustomerSettings }
)(App)
