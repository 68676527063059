import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getGlobalMappings,
  getGlobalTemplates,
  submitGlobalMappings,
  submitGlobalTemplateMappings
} from 'src/actions/QuickBooksActions'
import { addMessage } from 'src/actions/ToastActions'
import Utils from 'src/utils/Utils'
import MappingCard from 'src/components/MappingCard'
import Loading from 'src/components/Loader'
import Error from 'src/components/Error'


const mappingHeaders = ['Account Type', 'Default Account']

const templatingHeaders = ['Type', 'Template']

const mappingColumnData = {
  'Inventory Asset (In Transit)': {
    accountTypes: ['OTHER_CURRENT_ASSET'],
    accountSubTypes: ['Inventory'],
    idField: 'inTransitInventoryAssetAccountId',
    documentationId: '2boB1eXZuMu66BmF7i1OjY'
  },
  'Income—Sales of Products': {
    accountTypes: ['INCOME'],
    accountSubTypes: ['SalesOfProductIncome'],
    idField: 'incomeSalesOfProductIncomeAccountId',
    documentationId: '2ChqWvLGSJwwaqwIqVhyun'
  },
  'Accounts Payable': {
    accountTypes: ['ACCOUNTS_PAYABLE', 'OTHER_CURRENT_ASSET'],
    accountSubTypes: ['AccountsPayable', 'Inventory'],
    idField: 'accountsPayableAccountId',
    documentationId: '6XSnpTfBzzPA4zl13FJDfa'
  }
}

const templatingColumnData = {
  'Product Template': {
    templatingValues: [{ templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}' },
      { templateDisplayValue: 'Product Sku', templateInputValue: '{{productSku}}' },
      { templateDisplayValue: 'Product Name', templateInputValue: '{{productName}}' },
      { templateDisplayValue: 'Inventory/Non-Inventory Indication', templateInputValue: '{{isInventoryTracking}}' }],
    idField: 'productNameTemplate',
    lines: [{ id: "DEFAULT", name: "" }]
  },
  'Tax Item Template': {
    templatingValues: [{ templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}' },
      { templateDisplayValue: 'Sales Tax Payable Account Id', templateInputValue: '{{salesTaxPayableAccountId}}' }],
    idField: 'taxProductNameTemplate',
    lines: [{ id: "DEFAULT", name: "" }]
  },
  'Customer Template': {
    templatingValues: [
      { templateDisplayValue: 'Customer Name', templateInputValue: '{{customerName}}' },
      { templateDisplayValue: 'Ship Address Line 1', templateInputValue: '{{shipAddressLine1}}' },
      { templateDisplayValue: 'Ship Address Line 2', templateInputValue: '{{shipAddressLine2}}' },
      { templateDisplayValue: 'Ship Address Line 3', templateInputValue: '{{shipAddressLine3}}' },
      { templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}' },
      { templateDisplayValue: 'City', templateInputValue: '{{city}}' },
      { templateDisplayValue: 'State/Province', templateInputValue: '{{stateProvince}}' },
      { templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}' }],
    idField: 'customerNameTemplate',
    lines: [{ id: "DEFAULT", name: "" }]
  },
  'Vendor Template': {
    templatingValues: [
      { templateDisplayValue: 'Vendor Name', templateInputValue: '{{vendorName}}' },
      { templateDisplayValue: 'Vendor Email', templateInputValue: '{{vendorEmail}}' }],
    idField: 'vendorNameTemplate',
    lines: [{ id: "DEFAULT", name: "" }]
  }
}


export class GlobalMappings extends Component {
  static defaultProps = { enabled: true }

  state = {
    globalMappings: undefined,
    globalTemplateMappings: undefined,
    globalMappingsError: false,
    sortedRows: [],
    saving: false
  }

  async componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const globalMappingsResponse = await getGlobalMappings()
    const globalTemplateMappingResponse = await getGlobalTemplates()

    if (globalMappingsResponse.status !== 200) {
      this.setState({ globalMappingsError: globalMappingsResponse })
    } else {
      const globalMappings = await globalMappingsResponse.json()
      const globalTemplates = await globalTemplateMappingResponse.json();
      this.setState({ globalMappings, error: false, globalTemplates })
    }
  }

  buildDefaultSelectData = (quickbooksAccounts, accountTypes, accountSubTypes, idToFind, idToFindAllowed, idField) => {
    const filteredAccounts = Utils.filterAccountsByType(quickbooksAccounts, accountTypes, accountSubTypes)
    const selectedAccount = idToFind ? filteredAccounts.find(account => account.id === idToFind) : null
    let disabled = false

    if (idField === 'incomeSalesOfProductIncomeAccountId' && !idToFindAllowed) {
      disabled = true
    }

    return { options: filteredAccounts, selectedId: selectedAccount?.id, idField, disabled }
  }

  buildDefaulTemplatingData = (templateContents, templateValues, idField, useDefault) => {
    return { name: templateContents, templatingValues: templateValues, idField: idField, lineValue: '', useDefault }
  }

  submitForm = async formData => {
    const { enabled } = this.props

    if (enabled) {
      this.setState({ saving: true })
      const globalMappings = {}

      Object.values(this.getSortedRows(this.state.globalMappings)).forEach(([k, v], i) => {
        globalMappings[v.idField] = formData[i].selectedIds[0]
      })

      submitGlobalMappings(globalMappings).then(() => {
        this.setState({ saving: false, globalMappings: globalMappings })
        this.props.addMessage({ type: 'Success', message: 'Your global mappings have been saved' })
      })
    }
  }

  submitTemplateForm = async formData => {
    this.setState({ saving: true })
    const globalMappings = {}
    Object.values(this.getSortedRowsForTemplating(this.state.globalMappings)).forEach(([k, v], i) => {
      globalMappings[v.idField] = formData[i].name
      globalMappings[v.idField + "UseDefault"] = formData[i].useDefault
    })

    await submitGlobalTemplateMappings(globalMappings)
    this.setState({saving: false, globalMappings: globalMappings})
    this.props.addMessage({type: 'Success', message: 'Your template global mappings have been saved'})
  }

  getSortedRows = globalMappings => {
    const { quickbooksAccounts } = this.props.quickbooks
    const secondColumn = Object.values(mappingColumnData)
    .map(({ accountTypes, accountSubTypes, idField }) => this.buildDefaultSelectData(
      quickbooksAccounts,
      accountTypes,
      accountSubTypes,
      globalMappings[idField],
      globalMappings.incomeSalesOfProductIncomeUpdateAllowed,
      idField
    ))

    const rows = Object.entries(mappingColumnData).map(([name, value], i) => [{
      name,
      documentationId: value.documentationId
    }, secondColumn[i]])
    return rows.sort((a, b) => a[0].name > b[0].name ? 1 : -1)
  }

  getSortedRowsForTemplating = globalMappings => {
    const secondColumn = Object.values(templatingColumnData)
    .map(({ idField, templatingValues }) => this.buildDefaulTemplatingData(globalMappings[idField],
      templatingValues,
      idField,
      globalMappings[idField + 'UseDefault']))
    const rows = Object.entries(templatingColumnData).map(([name, value], i) => [{
      name,
      documentationId: value.documentationId,
      idField: value.idField
    }, secondColumn[i]])
    return rows.sort((a, b) => a[0].name > b[0].name ? 1 : -1)
  }

  render() {
    const { enabled, quickbooks: { quickbooksAccounts, error } } = this.props
    const { globalMappings, globalTemplates, globalMappingsError, saving } = this.state

    if (globalMappingsError || error) {
      const { status, message } = error || globalMappingsError
      return <Error status={status} message={message}/>
    } else if (quickbooksAccounts && globalMappings) {
      const sortedRows = this.getSortedRows(globalMappings)
      const templatedSortedRows = this.getSortedRowsForTemplating(globalTemplates)
      return (
        <div style={{ marginTop: '20px' }}>
          <h3>Global Mappings</h3>

          <MappingCard
            headers={mappingHeaders}
            enabled={enabled}
            rows={sortedRows}
            saving={saving}
            submitForm={this.submitForm}
          >
          </MappingCard>

          <h3 style={{ marginTop: '20px' }}>Global Naming Templates</h3>

          <MappingCard
            headers={templatingHeaders}
            enabled={enabled}
            rows={templatedSortedRows}
            saving={saving}
            submitForm={this.submitTemplateForm}
          >
          </MappingCard>
        </div>
      )
    }
    return <Loading/>
  }
}

export default connect(
  ({ quickbooks }) => ({ quickbooks }),
  { addMessage }
)(GlobalMappings)