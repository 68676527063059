import React from 'react'
import { connect } from 'react-redux'
import {
  getShippingProviderMappingStatuses,
  getShippingProviderMappings,
  submitShippingProviderMappings
} from "src/actions/QuickBooksActions"
import Utils from "src/utils/Utils"
import Loader from 'src/components/Loader'
import MappingSettings from 'src/components/MappingSettings'

class ShippingProviders extends React.PureComponent {
  generateSections = (quickbooks) => {
    const filterAccounts = (types, subtypes) => Utils.filterAccountsByType(quickbooks.quickbooksAccounts, types, subtypes)

    return {
      accountMapping: [
        {
          title: 'Shipping Services COGS Account',
          key: 'shippingServicesCogsAccountId',
          documentationId: '5pxGzc92RkPXBfA6cWW2OC',
          options: filterAccounts(['COST_OF_GOODS_SOLD'], ['ShippingFreightDeliveryCos'])
        },
        {
          title: 'Shipping Services A/P Account',
          key: 'shippingServicesCreditAccountId',
          documentationId: '3dN7AuTiA7w6DWtanl3F4l',
          options: filterAccounts(['BANK', 'ACCOUNTS_PAYABLE'], ['AccountsPayable', 'Checking', 'Savings'])
        },
        {
          title: 'Vendor',
          key: 'vendorId',
          documentationId: '6ZKh7RqPZfoadyXxSKN1Je',
          options: this.props.quickbooks.quickbooksVendors || []
        }
      ],
      tracking: [
        {
          title: 'Shipment Created',
          key: 'shipmentCreatedTrackSetting',
          documentationId: '5TzB3X4mkBiDnIlKchOxpo'
        }
      ],
      customization: [
        {
          title: 'Shipment Created',
          key: 'shipmentCreatedCustomizationSettings',
          customizationOptions: [
            { id: 'ORDER_SHIP_DATE', name: 'Order Ship Date' },
            { id: 'ORDER_DATE', name: 'Order Date' },
            { id: 'ORDER_PAYMENT_DATE', name: 'Order Payment Date' },
            { id: 'ORDER_CREATED_DATE', name: 'Order Created Date' },
            { id: 'ORDER_SHIPMENT_CREATED_DATE', name: 'Shipment Created Date' },
            { id: 'ORDER_SHIPMENT_LABEL_CREATED_DATE', name: 'Shipment Label Created Date' }
          ]
        }
      ],
      templates: [
        {
          title: 'Shipment Created',
          key: 'shipmentCreatedTemplateSettings',
          idField: 'shipmentCreatedTemplateSettings',
          templatingValues: [
            {templateDisplayValue: 'Order Number', templateInputValue: '{{orderNumber}}'},
            {templateDisplayValue: 'Sales Channel Name', templateInputValue: '{{salesChannelName}}'},
            {templateDisplayValue: 'Shipping Provider Name', templateInputValue: '{{shipmentProviderName}}'},
            {templateDisplayValue: 'Shipping Service Name', templateInputValue: '{{shipmentServiceName}}'},
            {templateDisplayValue: 'Shipping Package Name', templateInputValue: '{{shipmentPackageName}}'},
            {templateDisplayValue: 'Buyer Name', templateInputValue: '{{buyerName}}'},
            {templateDisplayValue: 'Buyer Email', templateInputValue: '{{buyerEmail}}'},
            {templateDisplayValue: 'Address', templateInputValue: '{{address}}'},
            {templateDisplayValue: 'Zip Code', templateInputValue: '{{zipCode}}'},
            {templateDisplayValue: 'State Province', templateInputValue: '{{stateProvince}}'},
            {templateDisplayValue: 'Country Code', templateInputValue: '{{countryCode}}'}
          ],
          lines: [
            {id: "CREDITS", name: "Credit Line"},
            {id: "DEBITS", name: "Debit Line"}
          ]
        }
      ]
    }
  }

  render() {
    const { quickbooks } = this.props
    const headerRow = [
      'Shipping Provider', 'Shipping Services Account', 'Shipping Services Credit Account', 'Actions'
    ]
    const displayRows = ['shippingServicesCogsAccountId', 'shippingServicesCreditAccountId']

    if (!quickbooks.quickbooksAccounts.length > 0) return <Loader />

    return <MappingSettings
      title='Shipping Providers'
      documentationId='4aCs3W2WLMrrKi0NqEsAod'
      headerRow={ headerRow }
      displayRows={ displayRows }
      idField='shippingProviderId'
      getMappings={ getShippingProviderMappings }
      getMappingStatuses={ getShippingProviderMappingStatuses }
      submitMapping={ submitShippingProviderMappings }
      quickbooks={ quickbooks }
      generateSections={ this.generateSections }
    />
  }
}

export default connect(({ quickbooks }) => ({ quickbooks }))(ShippingProviders)
