export default onAuthFailure => (endpointPath, method = 'GET', body = undefined, queryParams = undefined) => {

  const baseUrl = process.env.REACT_APP_API_URL;
  const queryString = queryParams ? '&' + queryParams : '';

  let headers = {
    'Authorization': localStorage.getItem('skubana:auth')
  }
  if (body) {
    headers['Content-Type'] = 'application/json';
  }

  return fetch(baseUrl + endpointPath + '?' + queryString, {
    method, body: JSON.stringify(body), headers
  })
  // let's assume we're always getting JSON back
  .then(async res => {
    // here we can check for whatever the API does
    // when it fails auth
    if (res.status === 401) {
      console.error(res)
      throw Error('rejected')
    }
    const json = await res.json();
    return Object.assign(json, { __meta: res });
  })
  .catch(err => {
    // Now we can call the function
    // in this scenario
    if (err.message === 'rejected') {
      onAuthFailure()
      console.error(err)
      return
    }
    // other wise we just want to handle our normal
    // rejection
    throw err
  })
}
