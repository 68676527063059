import React, { PureComponent } from 'react'
import { Button } from 'cargo'

import Select from 'src/components/Select'
import ToggleSwitch from 'src/components/ToggleSwitch'
import MappingStatus from 'src/components/MappingStatus'

import './Settings.scss'
import DocumentedLabel from './DocumentedLabel'

const JOURNAL_ENTRY_TYPES = ['JOURNAL_ENTRY_RECEIPT', 'JOURNAL_ENTRY_INVOICE']

export default class TrackingSettings extends PureComponent {
  optionsWithPotentialDefault = (options, showNA, showSelect) => {
    const modifiedOptions = options.map(option => ({...option}))
    if (!this.props.mappingRef.defaultMapping) {
      modifiedOptions.unshift({ id: 'USE_DEFAULT', name: 'Use Default' })
    }

    if (showNA) {
      modifiedOptions.unshift({ id: 'NA', name: 'N/A', value: null})
    } else if (showSelect) {
      modifiedOptions.unshift({ id: null, name: "Select...", value: null })
    }

    return [...modifiedOptions]
  }

  batchIntervalOptions = showNA => this.optionsWithPotentialDefault( [
    { id: 'HOURLY', name: 'Hourly' },
    { id: 'DAILY', name: 'Daily' },
    { id: 'WEEKLY', name: 'Weekly' },
    { id: 'MONTHLY', name: 'Monthly' },
    { id: 'QUARTERLY', name: 'Quarterly' },
    { id: 'SEMIANNUALLY', name: 'Semi-Annually' },
    { id: 'ANNUALLY', name: 'Annually' }
  ], showNA)

  trackingMethodOptions = showNA => this.optionsWithPotentialDefault([
    { id: 'TRANSACTIONAL', name: 'Transactional' },
    { id: 'BATCHED', name: 'Batched' }
  ], showNA)

  setAllSync = enabled => () => {
    this.props.rows.forEach(({ key }) => this.props.handleSettingChange(key, 'enabled')({ target: { value: enabled } }))
  }

  render() {
    const { rows, handleSettingChange, handleMappingChange, mappingRef, fields, mappingStatuses = [], isDefaultMapping } = this.props

    return (
      <div className='Section'>
        <div className='SectionHeader'>
          <p className='SectionTitle'>Tracking Settings</p>
          <div className='SettingGlobalActions'>
            <Button text='Disable All Flows' onClick={ this.setAllSync(false) } secondary />
            <Button
              text='Enable All Flows'
              onClick={ this.setAllSync(true) }
              color='#fff'
              primary
              style={{ marginLeft: 10 }}
            />
          </div>
        </div>
        <div className='SettingRowContainer'>
          <div className='SettingHeader'>
            <div><p>Flow</p></div>
            <div><p>Mapping Status</p></div>
            <div><p>Tracking Type</p></div>
            <div><p>Tracking Method</p></div>
            <div><p>Tracking Interval</p></div>
            <div><p>Enable Sync</p></div>
          </div>
          {rows.map(({ title, documentationId, key, trackingTypeKey, trackingTypeOptions = [], trackingMethodOptionsEnabled = true }) => {
            const isTrackingTypeOptionsDisabled = !mappingRef[key].enabled
            const isTrackingMethodOptionsDisabled = isTrackingTypeOptionsDisabled || (!trackingMethodOptionsEnabled || !mappingRef[key].enabled ||
              (!JOURNAL_ENTRY_TYPES.includes(mappingRef[trackingTypeKey]) && trackingTypeOptions.length > 0))
            const isBatchIntervalOptionsDisabled = isTrackingMethodOptionsDisabled || (mappingRef[key].trackingMethod !== 'BATCHED' || !mappingRef[key].enabled)

            return (
              <div className='SettingRow' key={ key }>
                <div>
                  <DocumentedLabel documentationId={ documentationId }>
                    { title }
                  </DocumentedLabel>
                </div>
                <div>
                  <MappingStatus
                    isDefaultMapping={ isDefaultMapping }
                    fields={ fields }
                    status={ mappingStatuses.find(m => m.type === key) }
                  />
                </div>
                <div>
                  {trackingTypeOptions.length ?
                    <Select
                      options={ this.optionsWithPotentialDefault(trackingTypeOptions, false, true) }
                      onChange={ handleMappingChange(trackingTypeKey) }
                      selected={ mappingRef[trackingTypeKey] }
                      disabled={ isTrackingTypeOptionsDisabled }
                    />
                    : 'Journal Entry'
                  }
                </div>
                <div>
                  <Select
                    options={ this.trackingMethodOptions(isTrackingMethodOptionsDisabled) }
                    onChange={ handleSettingChange(key, 'trackingMethod') }
                    selected={ mappingRef[key].trackingMethod }
                    disabled={ isTrackingMethodOptionsDisabled }
                  />
                </div>
                <div>
                  <Select
                    options={ this.batchIntervalOptions(isBatchIntervalOptionsDisabled) }
                    onChange={ handleSettingChange(key, 'batchInterval') }
                    selected={ mappingRef[key].batchInterval }
                    disabled={ isBatchIntervalOptionsDisabled }
                  />
                </div>
                <div>
                  <ToggleSwitch
                    enabled={ mappingRef[key].enabled }
                    onChange={ handleSettingChange(key, 'enabled') }
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}