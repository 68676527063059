import Utils from "src/utils/Utils"

export const QUICKBOOKS_FETCH_DATA = 'QUICK_BOOKS_FETCH_DATA'
export const QUICKBOOKS_FETCH_OAUTH_URL = 'QUICK_BOOKS_FETCH_OAUTH_URL'
export const QUICKBOOKS_ACCOUNT_DATA = 'QUICK_BOOKS_ACCOUNT_DATA'
export const QUICKBOOKS_ACCOUNT_ERROR = 'QUICK_BOOKS_ACCOUNT_ERROR'
export const SHIPPING_PROVIDER_FETCH_DATA = 'SHIPPING_PROVIDER_FETCH_DATA'
export const SHIPPING_PROVIDER_DATA = 'SHIPPING_PROVIDER_DATA'
export const SHIPPING_PROVIDER_ERROR = 'SHIPPING_PROVIDER_ERROR'
export const SHIPPING_CARRIER_FETCH_DATA = 'SHIPPING_CARRIER_FETCH_DATA'
export const SHIPPING_CARRIER_DATA = 'SHIPPING_CARRIER_DATA'
export const SHIPPING_CARRIER_ERROR = 'SHIPPING_CARRIER_ERROR'
export const USER_SETTINGS_FETCH_DATA = 'USER_SETTINGS_FETCH_DATA'
export const USER_SETTINGS_DATA = 'USER_SETTINGS_DATA'
export const USER_SETTINGS_ERROR = 'USER_SETTINGS_ERROR'
export const USER_SETTINGS_UPDATE_SKUBANA_AUTH = 'USER_SETTINGS_UPDATE_SKUBANA_AUTH'
export const USER_SETTINGS_UPDATE_QBO_AUTH = 'USER_SETTINGS_UPDATE_QBO_AUTH'
export const USER_LOGOUT = 'USER_LOGOUT'

export const submitQuickbooksAuthCode = (code, state, realmId = '') => {
  const queryParams = `code=${code}&state=${state}&realmId=${realmId}`
  return Utils.makeApiCall('/quickbooks/token', 'POST', undefined, queryParams)
}

export const submitSkubanaAuthCode = (code, cid) => {
  const queryParams = `code=${code}&cid=${cid}`;
  return Utils.makeApiCall('/skubana/token', 'POST', undefined, queryParams);
}

export const fetchCustomers = customerId => async (name = '', cb) => {
  const response = await Utils.makeApiCall(`/quickbooks/accounting/customers?name=${name}&customerId=${customerId}`)
  let results = []

  if (response.status === 200) {
    results = await response.json()
  }

  if (cb) return cb(results)

  return results
}

export const getQuickBooksOauthUrl = () => Utils.makeApiCall('/quickbooks/initoauth', 'POST')

export const getWarehouseMappings = () => Utils.makeApiCall('/skubana/warehouses/mapping')

export const getWarehouseMappingStatuses = async (mappings = []) => {
  const warehouseIds = mappings.map(({ warehouseId }) => warehouseId).filter(m => m)
  const actionTypes = [
    'ORDER_RETURNED',
    'ORDER_SHIPPED',
    'SHIPMENT_VOIDED',
    'PO_ITEM_MODIFIED_SHIPPED_COGS_ADJUST',
    'STO_ITEM_RECEIVED',
    'STO_ITEM_SHIPPED',
    'MARKETPLACE_INBOUND_SHIPMENT_RECEIVED',
    'IN_TRANSIT_STO_ITEM_ADJUST',
    'MARKETPLACE_INBOUND_SHIPMENT_SHIPPED',
    'IN_TRANSIT_MARKETPLACE_INBOUND_SHIPMENT_ADJUST',
    'PO_ITEM_RECEIVED',
    'PO_ITEM_MODIFIED',
    'ADD_INVENTORY',
    'REMOVE_INVENTORY'
  ]

  const typeMap = {
    ORDER_RETURNED: 'orderReturnedTrackSetting',
    ORDER_SHIPPED: 'orderShippedTrackSetting',
    SHIPMENT_VOIDED: 'shipmentVoidedTrackSetting',
    PO_ITEM_MODIFIED_SHIPPED_COGS_ADJUST: 'poItemAdjustTrackSetting',
    STO_ITEM_RECEIVED: 'stoItemReceivedTrackSetting',
    STO_ITEM_SHIPPED: 'stoItemShippedTrackSetting',
    MARKETPLACE_INBOUND_SHIPMENT_RECEIVED: 'marketplaceShipmentReceivedTrackSetting',
    IN_TRANSIT_STO_ITEM_ADJUST: 'stoItemAdjustTrackSetting',
    MARKETPLACE_INBOUND_SHIPMENT_SHIPPED: 'marketplaceShipmentShippedTrackSetting',
    IN_TRANSIT_MARKETPLACE_INBOUND_SHIPMENT_ADJUST: 'marketplaceShipmentAdjustTrackSetting',
    PO_ITEM_RECEIVED: 'poItemReceivedTrackSetting',
    PO_ITEM_MODIFIED: 'poItemModifiedTrackSetting',
    ADD_INVENTORY: 'addInventoryTrackSetting',
    REMOVE_INVENTORY: 'removeInventoryTrackSetting'
  }

  const warehouseMappingStatusResponse = await Utils.makeApiCall(
    '/mappingstatus/inventoryvaluechange',
    'GET',
    undefined,
    `externalWarehouseIds=${warehouseIds}&inventoryValueChangeActionTypes=${actionTypes}`
  )
  const warehouseMappingStatuses = await warehouseMappingStatusResponse.json()

  return warehouseMappingStatuses.reduce(
    (acc, { externalWarehouseId, ...status }) => ({ ...acc, [externalWarehouseId]: [...(acc[externalWarehouseId] || []), { ...status, type: typeMap[status.inventoryValueChangeActionType] }] }),
    {}
  )
}

export const getCustomerSettings = () => async (dispatch, state, { api }) => {
  const { user, userSettings } = state()

  try {
    if (user.authenticated) {
      dispatch({ type: USER_SETTINGS_FETCH_DATA })

      const userSettings = await api('/customers')

      if (userSettings.__meta.status === 200) {
        if (!userSettings.quickbooksAuthorized) {
          getQuickBooksOauthUrl().then(quickbooksOauthUrlResponse => {
            if (quickbooksOauthUrlResponse.status === 401) {
              Utils.redirectToSkubana()
            }

            return quickbooksOauthUrlResponse.text()
          }).then(quickbooksOauthUrl => {
            dispatch({ type: QUICKBOOKS_FETCH_OAUTH_URL, payload: quickbooksOauthUrl })
          })
        } else {
          dispatch(getQuickBooksAccounts())
        }

        dispatch({ type: USER_SETTINGS_DATA, payload: userSettings })
      } else {
        await dispatch({ type: USER_LOGOUT, payload: {} })
        dispatch({ type: USER_SETTINGS_ERROR, payload: { error: userSettings } })
      }
    }
  }
  catch (error) {
    console.error(error)
    dispatch({ type: USER_SETTINGS_ERROR, payload: { error: userSettings } })
  }
}

export const saveCustomerSettings = customerSettings => dispatch => Utils.makeApiCall(
  '/customers/settings',
  'PUT',
  customerSettings
)

export const submitWarehouseMappings = warehouseMappings => Utils.makeApiCall(
  '/skubana/warehouses/mapping',
  'PUT',
  warehouseMappings
)

export const getSalesChannelMappings = () => Utils.makeApiCall('/skubana/saleschannels/mapping')

export const getSalesChannelMappingStatuses = async (mappings = []) => {
  const channelIds = mappings.map(({ salesChannelId }) => salesChannelId)

  const channelFeeMappingStatusResponse = await Utils.makeApiCall(
    '/mappingstatus/channelfee',
    'GET',
    undefined,
    `externalChannelIds=${channelIds}`
  )
  let channelFeeMappingStatuses = await channelFeeMappingStatusResponse.json()
  channelFeeMappingStatuses = channelFeeMappingStatuses
    .map(status => ({ ...status, type: 'salesChannelFeeCreatedTrackSetting' }))

  const orderMappingStatusResponse = await Utils.makeApiCall(
    '/mappingstatus/order',
    'GET',
    undefined,
    `externalChannelIds=${channelIds}`
  )
  let orderMappingStatuses = await orderMappingStatusResponse.json()
  orderMappingStatuses = orderMappingStatuses
    .map(status => ({ ...status, type: 'orderShippedTrackSetting' }))

  const returnMappingStatusResponse = await Utils.makeApiCall(
    '/mappingstatus/return',
    'GET',
    undefined,
    `externalChannelIds=${channelIds}`
  )
  let returnMappingStatuses = await returnMappingStatusResponse.json()
  returnMappingStatuses = returnMappingStatuses
    .map(status => ({ ...status, type: 'orderReturnedTrackSetting' }))

  return [...channelFeeMappingStatuses, ...orderMappingStatuses, ...returnMappingStatuses]
    .filter(({ externalChannelId }) => externalChannelId != null)
    .reduce(
      (acc, { externalChannelId, ...status }) => ({ ...acc, [externalChannelId]: [...(acc[externalChannelId] || []), status] }),
      {}
    )
}

export const submitSalesChannelMappings = salesChannelMappings => Utils.makeApiCall(
  '/skubana/saleschannels/mapping',
  'PUT',
  salesChannelMappings
)

export const getShippingCarrierMappings = () => Utils.makeApiCall('/skubana/shippingcarriers/mapping')

export const getShippingCarrierMappingStatuses = async (mappings = []) => {
  const shipmentCarrierIds = mappings.map(({ shippingCarrierId }) => shippingCarrierId)

  const shippingCarrierMappingStatusResponse = await Utils.makeApiCall(
    '/mappingstatus/shipment/carrier',
    'GET',
    undefined,
    `externalShipmentCarrierIds=${shipmentCarrierIds}`
  )
  const shippingCarrierMappingStatuses = await shippingCarrierMappingStatusResponse.json()

  return shippingCarrierMappingStatuses.map(status => ({ ...status, type: 'shipmentCreatedTrackSetting' }))
    .reduce(
      (acc, { shipmentProviderCarrierId, ...status }) => ({ ...acc, [shipmentProviderCarrierId]: [...(acc[shipmentProviderCarrierId] || []), status] }),
      {}
    )
}

export const submitShippingCarrierMappings = shippingCarrierMappings => Utils.makeApiCall(
  '/skubana/shippingcarriers/mapping',
  'PUT',
  shippingCarrierMappings
)

export const getShippingProviderMappings = () => Utils.makeApiCall('/skubana/shippingproviders/mapping')

export const getShippingProviderMappingStatuses = async (mappings = []) => {
  const shipmentProviderIds = mappings.map(({ shippingProviderId }) => shippingProviderId)

  const shippingProviderMappingStatusResponse = await Utils.makeApiCall(
    '/mappingstatus/shipment/provider',
    'GET',
    undefined,
    `externalShipmentProviderIds=${shipmentProviderIds}`
  )
  const shippingProviderMappingStatuses = await shippingProviderMappingStatusResponse.json()

  return shippingProviderMappingStatuses.map(status => ({ ...status, type: 'shipmentCreatedTrackSetting' }))
    .reduce(
      (acc, { shipmentProviderCarrierId, ...status }) => ({ ...acc, [shipmentProviderCarrierId]: [...(acc[shipmentProviderCarrierId] || []), status] }),
      {}
    )
}

export const submitShippingProviderMappings = shippingProviderMappings => Utils.makeApiCall(
  '/skubana/shippingproviders/mapping',
  'PUT',
  shippingProviderMappings
)

export const getGlobalMappings = () => Utils.makeApiCall('/skubana/mapping/defaults')

export const getGlobalTemplates = () => Utils.makeApiCall('/skubana/mapping/globaltemplates')

export const submitGlobalMappings = globalMappings => Utils.makeApiCall(
  '/skubana/mapping/defaults',
  'PUT',
  globalMappings
)

export const submitGlobalTemplateMappings = globalMappings => Utils.makeApiCall(
    '/skubana/mapping/globaltemplates',
    'PUT',
    globalMappings
)

export const getQuickBooksAccounts = () => async (dispatch, state, { api }) => {
  dispatch({ type: QUICKBOOKS_FETCH_DATA, payload: { loading: true } })

  const [quickbooksAccounts, quickbooksVendors] = await Promise.all([
    api('/quickbooks/accounting/accounts'),
    api('/quickbooks/accounting/vendors')
  ])

  if (quickbooksAccounts.__meta.status === 200 && quickbooksVendors.__meta.status === 200) {
    dispatch({
      type: QUICKBOOKS_ACCOUNT_DATA,
      payload: { quickbooksAccounts, quickbooksVendors }
    })
  } else {
    dispatch({ type: QUICKBOOKS_ACCOUNT_ERROR, payload: { error: quickbooksAccounts } })
  }
}