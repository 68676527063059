import React from 'react'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { composeWithDevTools } from 'redux-devtools-extension'
import { CargoContext } from 'cargo'
import App from "./layouts/Primary/App"
import skubanaApi from './services/skubanaApi'
import { logout } from './actions/UserActions'
import rootReducer from './reducers/index'

import './index.css'
import Utils from "./utils/Utils";

if (process.env.REACT_APP_APP_ENV !== 'local') {
  Sentry.init({
    dsn: 'https://c2b6eb6bc8be4a16b99027d3ca6c4409@sentry.io/2411057'
  })
}

export const Store = createStore(rootReducer, {}, composeWithDevTools(
  applyMiddleware(thunk.withExtraArgument({
    api: skubanaApi(() => {
      Store.dispatch(logout())
    })
  })),
))

ReactDOM.render(
  <CargoContext.Provider value={{ primaryColor: 'linear-gradient(135deg, #8f75da 0%, #727cf5 60%)', secondaryColor: '#dadada' }}>
    <Provider store={ Store }>
      <App />
    </Provider>,
  </CargoContext.Provider>,
  document.getElementById('root')
)

Utils.checkIfJWTIsPresent()