import React from 'react'

import './ToggleSwitchWithLabel.scss'
import ToggleSwitch from './ToggleSwitch';

const ToggleSwitchWithLabel = ({ enabled, onChange, labelText }) => (
  <div className="SwitchWithLabelContainer">
    <div>
      <div className="SwitchWithLabelSwitchContainer">
        <ToggleSwitch enabled={ enabled } onChange={ onChange }/>
      </div>
      <p className="SwitchWithLabelLabel">
        { labelText }
      </p>
    </div>
  </div>
)

export default ToggleSwitchWithLabel