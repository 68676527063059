import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faPowerOff, } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { logout } from 'src/actions/UserActions'
import './TopBar.scss'

library.add(faBars, faPowerOff)

const TopBar = ({ toggleNav, user, logout, admin }) => (
  <div id='vertical-topbar-placeholder'>
    <div className='navbar-custom'>
      {admin && (
        <button className='button-menu-mobile open-left disable-btn' onClick={ toggleNav }>
          <i className='mdi mdi-menu' id='button-menu-mobile-toggle'>
            <FontAwesomeIcon icon='bars' />
          </i>
        </button>
      )}
      <ul className='list-unstyled topbar-right-menu float-right mb-0'>
        {user.authenticated && (
          <li className='dropdown notification-list'>
            <span
              className='nav-link d-flex align-items-center nav-user arrow-none mr-0'
              data-toggle='dropdown'
              onClick={ logout }
              role='button'
              aria-haspopup='false'
              aria-expanded='false'
            >
              <div className='mr-2'>
                <FontAwesomeIcon icon='power-off'/>
              </div>
              <div>
                <span className='account-user-name'>Logout</span>
              </div>
            </span>
          </li>
        )}
      </ul>
    </div>
  </div>
)

export default connect(
  ({ user }) => ({ user }),
  { logout }
)(TopBar)
