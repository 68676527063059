import {
  SHIPPING_CARRIER_DATA,
  SHIPPING_CARRIER_ERROR,
  SHIPPING_CARRIER_FETCH_DATA,
  SHIPPING_PROVIDER_DATA,
  SHIPPING_PROVIDER_ERROR,
  SHIPPING_PROVIDER_FETCH_DATA,
} from 'src/actions/QuickBooksActions';


const initialState = {
  loading: true,
  shippingProviderError: false,
  shippingCarrierError: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SHIPPING_PROVIDER_FETCH_DATA:
      return { ...state, ...payload, loading: true, shippingProviderError: false }
    case SHIPPING_PROVIDER_DATA:
      return { ...state, ...payload, loading: false, shippingProviderError: false }
    case SHIPPING_PROVIDER_ERROR:
      return { ...state, ...payload, loading: false }
    case SHIPPING_CARRIER_FETCH_DATA:
      return { ...state, ...payload, loading: true, shippingCarrierError: false }
    case SHIPPING_CARRIER_DATA:
      return { ...state, ...payload, loading: false, shippingCarrierError: false }
    case SHIPPING_CARRIER_ERROR:
      return { ...state, ...payload, loading: false }
    default:
      return state
  }
}

