import React, { PureComponent } from 'react'

import './Settings.scss'
import Select from './Select'
import DocumentedLabel from './DocumentedLabel'

export default class CustomizationSettings extends PureComponent {
  optionsWithPotentialDefault = options => {
    const modifiedOptions = options.map(option => ({...option}))

    if (!this.props.mappingRef.defaultMapping) {
      modifiedOptions.unshift({ id: 'USE_DEFAULT', name: 'Use Default' })
    } else {
      modifiedOptions.unshift({ id: null, name: "Select...", value: null })
    }

    return modifiedOptions
  }

  render() {
    const { rows, handleSettingChange, mappingRef } = this.props

    return (
      <div className='Section'>
        <div className='SectionHeader'>
          <p className='SectionTitle'>Customization Settings</p>
        </div>
        <div className='SettingRowContainer'>
          <div className='SettingHeader'>
            <div><p>Flow</p></div>
            <div><p>Transaction Date Field</p></div>
          </div>
          {rows.map(({ title, documentationId, key, customizationOptions = [] }) => {
            return (
              <div className='SettingRow' key={ title }>
                <div>
                  <DocumentedLabel documentationId={ documentationId }>
                    { title }
                  </DocumentedLabel>
                </div>
                <div>
                  {Array.isArray(customizationOptions) ?
                    <Select
                      options={ this.optionsWithPotentialDefault(customizationOptions) }
                      onChange={ handleSettingChange(key, 'transactionDateField') }
                      selected={ mappingRef[key].transactionDateField }
                    />
                    : customizationOptions
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
