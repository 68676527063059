import React from 'react'

import 'src/components/Select.scss'

const Select = ({ options = [], onChange, selected, disabled }) => {
  let selectedOption = options.find(o => o.id === selected)

  if (selectedOption && !disabled) {
    selectedOption.selected = true
  } else {
    options[0].selected = true
  }

  return (
    <select
      className='SelectDropdown'
      onChange={ onChange }
      disabled={ disabled }
    >
      {options.map(option => (<option key={ option.id } selected={ option.selected } value={ option.id }>{ option.name }</option>))}
    </select>
  )
}

export default Select