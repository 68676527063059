import React from 'react'
import { connect } from 'react-redux'
import { Documentation, toggleModal, popModalLayer, pushModalLayer } from 'cargo'

import './DocumentedLabel.scss'

const DocumentedLabel = ({ children, documentationId, toggleModal, popModalLayer, pushModalLayer, cargo }) => {
  const showDocumentation = () => {
    if (!documentationId) return

    const title = Array.isArray(children) ? children[0] : children

    const isModalOpen = Object.keys(cargo.modal.contentLayers[0]).length

    if (isModalOpen) {
      pushModalLayer({
        title: `${title} Documentation`,
        body: <Documentation entryId={ documentationId } />,
        cancelText: 'Close',
        onCancel: popModalLayer
      })
    } else {
      toggleModal({
        title: `${title} Documentation`,
        body: <Documentation entryId={ documentationId } />,
        cancelText: 'Close',
        onCancel: () => toggleModal(false)
      })
    }
  }

  return (
    <p
      className={`LabelText ${ documentationId ? 'WithDoc' : '' }`}
      title={ documentationId ? 'What is this?' : null }
      onClick={ showDocumentation }
    >
      { children }
    </p>
  )
}

export default connect(
  ({ cargo }) => ({ cargo }),
  { toggleModal, pushModalLayer, popModalLayer }
)(DocumentedLabel)